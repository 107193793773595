<script>
    import { getDatabase, update, ref } from "firebase/database";
    import { savingData, userId } from "../../../../../stores";

    import { v4 as uuidv4 } from "uuid";

    const db = getDatabase();

    let userIdString = $userId;

    const customRef = ref(db, `users/${userIdString}/custom/`);

    export let wallType;
    export let allWallTypes;
    export let isPropagating;
    export let currentTool;
    export let allWallCoordinates;

    let addingWall = false;
    let deletingWall = false;
    let wallToDeleteString = "";
    let wallToEdit = {};

    let editingWall = false;

    let currentColor = "";
    let newWallName = "";
    let newWallAtten = 0;
    let newWallWidth = 0;
    let wallToDeleteId = "";

    const addWallHandler = () => {
        allWallTypes.push({
            name: newWallName,
            attenuation: newWallAtten,
            width: newWallWidth,
            color: currentColor,
            id: uuidv4(),
        });

        allWallTypes = allWallTypes;

        update(customRef, {
            walls: allWallTypes,
        });

        addingWall = false;

        savingData.set(true);
    };

    const deleteWallHandler = (wallToDelete) => {
        deletingWall = false;
        allWallTypes = allWallTypes.filter(
            (wall) => wall.id !== wallToDeleteId
        );
        update(customRef, {
            walls: allWallTypes,
        });

        wallToDeleteString = "";
        wallToDeleteId = "";

        savingData.set(true);
    };

    const editWallHandler = () => {
        for (let wall of allWallTypes) {
            if (wall.id === wallToEdit.id) {
                wall.name = wallToEdit.name;
                wall.attenuation = wallToEdit.attenuation;
                wall.color = wallToEdit.color;
                wall.width = wallToEdit.width;
            }
        }

        allWallTypes = allWallTypes;

        update(customRef, {
            walls: allWallTypes,
        });

        for (let wall of allWallCoordinates) {
            if (wall.type_id == wallToEdit.id) {
                wall.name = wallToEdit.name;
                wall.attenuation = wallToEdit.attenuation;
                wall.strokeStyle = wallToEdit.color;
                wall.width = wallToEdit.width;
            }
        }
        allWallCoordinates = allWallCoordinates;

        editingWall = false;

        savingData.set(true);
    };
</script>

<main>
    <div class="dropdown-menu more-options">
        <div style="display: flex; justify-content: center;">
            <div class="tool-settings-title">Wall Settings</div>
        </div>
        {#if !isPropagating}
            <div class="specific-setting">
                <div style="display: flex; justify-content: space-between;">
                    {#if editingWall}
                        <div class="ts-subtitle">Edit Wall</div>
                        <div
                            class="delete-freq"
                            style="font-size: 1.5rem;"
                            on:click={() => (editingWall = false)}
                        >
                            ×
                        </div>
                    {:else if addingWall}
                        <div class="ts-subtitle">Add Wall</div>
                        <div
                            class="delete-freq"
                            style="font-size: 1.5rem;"
                            on:click={() => (addingWall = false)}
                        >
                            ×
                        </div>
                    {:else if !addingWall && !editingWall && !deletingWall}
                        <div class="ts-subtitle">Type ⇔ Attenuation</div>
                        <div
                            class="add-wall"
                            on:click={() => (addingWall = true)}
                        >
                            +
                        </div>
                    {/if}
                </div>
                {#if addingWall}
                    <form on:submit|preventDefault={addWallHandler}>
                        <div style="display: flex; margin-top: 0.5rem;">
                            <div style="margin-right: 0.25rem;">Name:</div>
                            <input
                                type="text"
                                style="width: 100%;"
                                bind:value={newWallName}
                            />
                        </div>
                        <div style="display: flex; margin-top: 1.5rem;">
                            <div style="display: flex;">
                                <div style="margin-right: 0.25rem;">Atten:</div>
                                <input
                                    type="text"
                                    style="width: 100%;"
                                    bind:value={newWallAtten}
                                />
                                <div
                                    style="margin-left: 0.25rem; margin-right: 0.25rem;"
                                >
                                    dB
                                </div>
                                <div>|</div>
                                <div style="margin-left: 0.25rem;">Width:</div>
                                <input
                                    type="text"
                                    style="width: 100%;"
                                    bind:value={newWallWidth}
                                />
                                <div
                                    style="margin-left: 0.25rem; margin-right: 0.25rem;"
                                >
                                    px
                                </div>
                            </div>
                        </div>
                        <div class="color-wrap">
                            <div class="color-item">Color: {currentColor}</div>
                            <input
                                type="color"
                                id="favcolor"
                                name="favcolor"
                                bind:value={currentColor}
                                style="width: 4rem; height: 2rem; cursor: pointer;"
                            />
                        </div>
                        <button class="add-wall-button">Add Wall</button>
                    </form>
                {:else if deletingWall}
                    <div style="display: flex;">
                        <div>Delete '{wallToDeleteString}'?</div>
                        <div style="color:black; display: flex;">
                            <div
                                class="del-wall-q"
                                on:click={() =>
                                    deleteWallHandler(wallToDeleteId)}
                            >
                                yes
                            </div>
                            <div
                                class="del-wall-cancel"
                                on:click={() => (deletingWall = false)}
                            >
                                no
                            </div>
                        </div>
                    </div>
                {:else if editingWall}
                    <form on:submit|preventDefault={editWallHandler}>
                        <div style="display: flex; margin-top: 0.5rem;">
                            <div style="margin-right: 0.25rem;">Name:</div>
                            <input
                                type="text"
                                style="width: 100%;"
                                bind:value={wallToEdit.name}
                                placeholder={wallToEdit.name}
                            />
                        </div>
                        <div style="display: flex; margin-top: 1.5rem;">
                            <div style="display: flex;">
                                <div style="margin-right: 0.25rem;">Atten:</div>
                                <input
                                    type="text"
                                    style="width: 100%;"
                                    bind:value={wallToEdit.attenuation}
                                />
                                <div
                                    style="margin-left: 0.25rem; margin-right: 0.25rem;"
                                >
                                    dB
                                </div>
                                <div>|</div>
                                <div style="margin-left: 0.25rem;">Width:</div>
                                <input
                                    type="text"
                                    style="width: 100%;"
                                    bind:value={wallToEdit.width}
                                />
                                <div
                                    style="margin-left: 0.25rem; margin-right: 0.25rem;"
                                >
                                    px
                                </div>
                            </div>
                        </div>
                        <div class="color-wrap">
                            <div class="color-item">
                                Color: {wallToEdit.color}
                            </div>
                            <input
                                type="color"
                                bind:value={wallToEdit.color}
                                style="width: 4rem; height: 2rem; cursor: pointer;"
                            />
                        </div>
                        <button class="add-wall-button">Change Wall</button>
                    </form>
                {:else}
                    {#each allWallTypes as wall}
                        <div style="display: flex;">
                            <div
                                class="more-options-item dropdown-item"
                                on:click={() => {
                                    currentTool = "WALL";
                                    wallType = wall.name;
                                }}
                            >
                                <div class="wall-type-wrapper">
                                    <div
                                        class="wall-point"
                                        style={`background-color: ${wall.color};`}
                                    />
                                    <div class="wall-desc">
                                        {wall.name} ⇔ {wall.attenuation}dB
                                    </div>
                                </div>
                                <!-- <div>{wall.attenuation}dB</div> -->
                            </div>
                            <div style="display: flex;" class="wall-control">
                                <div
                                    class="edit-wall"
                                    on:click={() => {
                                        editingWall = true;
                                        wallToEdit = wall;
                                    }}
                                >
                                    ✎
                                </div>
                                <div
                                    on:click={() => {
                                        deletingWall = true;
                                        wallToDeleteString = wall.name;
                                        wallToDeleteId = wall.id;
                                    }}
                                    class="delete-freq"
                                >
                                    ×
                                </div>
                            </div>
                        </div>
                    {/each}
                {/if}
            </div>
        {:else}
            <div>propagating...</div>
        {/if}
    </div>
</main>

<style>
    .more-options-item {
        transition: 0.1s;
    }
    .more-options-item:hover {
        color: gray;
        cursor: pointer;
        transition: 0.1s;
    }
    .dropdown-item {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
        justify-content: center;
        font-size: 0.85rem;
        display: flex;
        justify-content: space-between;
        padding-right: 0.25rem;
    }

    .tool-settings-title {
        text-align: center;
        background-color: gray;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
        font-weight: bold;
    }

    .ts-subtitle {
        font-weight: bold;
    }

    .specific-setting {
        padding-top: 0.5rem;
    }

    .wall-desc {
        margin-left: 0.25rem;
    }

    .wall-point {
        height: 0.75rem;
        width: 0.75rem;
        /* border-radius: 4px; */
        border: 1px solid white;
        margin-right: 0.25rem;
    }

    .wall-type-wrapper {
        display: flex;
        width: 10rem;
        align-items: center;
    }

    .add-wall {
        font-size: 1.25rem;
        font-weight: bold;
        transition: 0.2s;
    }

    .add-wall:hover {
        color: gray;
        transition: 0.2s;
        cursor: pointer;
    }

    .color-wrap {
        display: flex;
        margin-top: 1.5rem;
        justify-content: space-between;
    }

    .color-item {
        display: flex;
        align-items: center;
        /* margin-left: 0.5rem; */
    }

    .add-wall-button {
        /* width: 5rem; */
        height: 2rem;
        background: none;
        color: black;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        background-color: gray;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 2px;
        margin-top: 1rem;
        font-weight: bold;
        transition: 0.2s;
    }

    .add-wall-button:hover {
        color: white;
        background-color: #00d1ff;
        transition: 0.2s;
    }

    .delete-freq {
        font-size: 1.25rem;
        color: gray;
        cursor: pointer;
        margin-left: 0.25rem;
        align-items: center;
        transition: 0.4s;
    }

    .delete-freq:hover {
        color: red;
        transition: 0.4s;
    }

    .edit-wall {
        font-size: 1rem;
        color: gray;
        cursor: pointer;
        /* margin-bottom: 0.5rem; */
        transition: 0.2s;
    }

    .edit-wall:hover {
        color: white;
        transition: 0.2s;
    }

    .wall-control {
        position: absolute;
        right: 1rem;
        /* top: 0.2rem; */
    }

    .del-wall-q {
        cursor: pointer;
        font-weight: bold;
        background-color: gray;
        border-radius: 2px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        margin-left: 1rem;
        margin-right: 0.25rem;
        transition: 0.4s;
    }

    .del-wall-q:hover {
        color: #00d1ff;
        /* background-color: red; */
        border-radius: 2px;
        transition: 0.4s;
    }

    .del-wall-cancel {
        cursor: pointer;
        background-color: gray;
        border-radius: 2px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        transition: 0.2s;
    }

    .del-wall-cancel:hover {
        transition: 0.2s;
        color: white;
        /* background-color: #00d1ff; */
    }
</style>

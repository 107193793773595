<script>
    import ToolTabs from "./ToolTabs.svelte";
    import WallSettings from "./WallSettings.svelte";
    import AntennaSettings from "./AntennaSettings.svelte";
    import ScaleSettings from "./ScaleSettings.svelte";
    import FrequencySettings from "./FrequencySettings.svelte";
    // import DeleteSettings from "./DeleteSettings.svelte";
    import CoaxSettings from "./CoaxSettings.svelte";
    import PropagateSettings from "./PropagateSettings.svelte";

    export let scaleLength;
    export let scaleLinePixelLength;
    export let scaleRatio;
    export let imageScale;
    export let currentFrequency;
    export let wallType;
    export let propagateButtonHandler;
    export let currentOutputPower;
    export let frequenciesList;
    export let outputPowerList;
    export let totalCoaxLength;
    export let deleteFreqHandler;
    export let deleteOutputPowerHandler;
    // export let newImageScale;
    export let currentNodeAmount;
    export let currentResolution;
    export let allWallTypes;
    export let isPropagating;
    export let currentTool;
    export let allWallCoordinates;

    let currentToolTab = "wall";
</script>

<main>
    <div class="overlay" id="tool-settings-box">
        <div id="tool-settings-title">
            <div style="font-weight: bold;">PARAMETERS</div>
        </div>
        <ToolTabs bind:currentToolTab />
        <div id="tool-settings">
            {#if currentToolTab === "wall"}
                <WallSettings
                    bind:wallType
                    {allWallTypes}
                    {isPropagating}
                    bind:currentTool
                    {allWallCoordinates}
                />
            {/if}
            {#if currentToolTab === "antenna"}
                <AntennaSettings
                    bind:currentOutputPower
                    {outputPowerList}
                    {deleteOutputPowerHandler}
                />
            {/if}
            {#if currentToolTab === "cable"}
                <CoaxSettings {totalCoaxLength} />
            {/if}
            {#if currentToolTab === "scale"}
                <ScaleSettings
                    bind:scaleLength
                    bind:scaleRatio
                    bind:imageScale
                    {scaleLinePixelLength}
                />
            {/if}
            {#if currentToolTab === "propagate"}
                <PropagateSettings
                    {propagateButtonHandler}
                    bind:currentNodeAmount
                    bind:currentResolution
                    {isPropagating}
                />
            {/if}
            {#if currentToolTab === "freqs"}
                <FrequencySettings
                    bind:currentFrequency
                    {frequenciesList}
                    {deleteFreqHandler}
                />
            {/if}
            <!-- {#if currentToolTab === "trash"}
                <DeleteSettings {clearButtonHandler} />
            {/if} -->
        </div>
    </div>
</main>

<style>
    .overlay {
        position: absolute;
        right: 1vw;
        /* margin-top: 1rem;
        margin-left: 1rem; */
        height: 40px;
        border-radius: 9px;
        /* padding-right: 1rem; */
        /* padding-left: 1rem; */
        width: 12rem;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    #tool-settings-box {
        /* padding-top: 1rem;
        padding-left: 1rem; */
        /* margin-left: 91.5rem; */
        background-color: #252321;
        /* background-color: black; */
        margin-top: 7vh;
        min-height: 20rem;
        height: 45vh;
        width: 14vw;
        min-width: 10rem;
        color: white;
    }

    #tool-settings {
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        /* border: 1px solid gray; */
        /* height: 28vh; */
        padding-right: 1rem;
        padding-left: 1rem;
    }

    #tool-settings-title {
        display: flex;
        justify-content: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
</style>

<script>
    export let dropdownTitle;
    export let menuToggleHandler;
    export let currentTool;
    export let wallType;
    export let isPropagating;
    export let allWallTypes;
    // export let propagateButtonHandler;

    // let trashOptionsToggle = false;
    let wallOptionsToggle = false;
</script>

<main>
    <div class="dropdown-menu" id="dropdown" style="margin-left: 26vw">
        {#if !isPropagating}
            <div
                class="dropdown-item"
                on:click={() => {
                    menuToggleHandler(dropdownTitle.toLowerCase(), false);
                    currentTool = "WALL";
                }}
                on:mouseover={() => {
                    // trashOptionsToggle = false;
                }}
                on:focus
            >
                <div class="options">
                    <div
                        on:mouseover={() => {
                            wallOptionsToggle = false;
                        }}
                        on:focus
                    >
                        Wall
                    </div>
                    <div
                        on:mouseover={() => {
                            wallOptionsToggle = true;
                        }}
                        on:focus
                    >
                        ➣
                    </div>
                </div>
                {#if wallOptionsToggle}
                    <div class="dropdown-menu more-options">
                        {#each allWallTypes as wall}
                            <div
                                class="more-options-item dropdown-item"
                                on:click={() => (wallType = wall.name)}
                            >
                                <div class="wall-type-wrapper">
                                    <div
                                        class="wall-point"
                                        style={`background-color: ${wall.color};`}
                                    />
                                    <div class="wall-desc">
                                        {wall.name}
                                    </div>
                                </div>
                            </div>
                        {/each}
                    </div>
                {/if}
            </div>
            <div
                class="dropdown-item"
                on:click={() => {
                    menuToggleHandler(dropdownTitle.toLowerCase(), false);
                    currentTool = "ANTENNA";
                }}
                on:mouseover={() => {
                    // trashOptionsToggle = false;
                    wallOptionsToggle = false;
                }}
                on:focus
            >
                Antenna
            </div>
            <div
                class="dropdown-item"
                on:click={() => {
                    menuToggleHandler(dropdownTitle.toLowerCase(), false);
                    currentTool = "CABLE";
                }}
                on:mouseover={() => {
                    // trashOptionsToggle = false;
                    wallOptionsToggle = false;
                }}
                on:focus
            >
                Cable
            </div>
            <div
                class="dropdown-item"
                on:click={() => {
                    menuToggleHandler(dropdownTitle.toLowerCase(), false);
                    currentTool = "ERASER";
                }}
            >
                <div class="options">
                    <div
                        on:mouseover={() => {
                            // trashOptionsToggle = false;
                            wallOptionsToggle = false;
                        }}
                        on:focus
                    >
                        Eraser
                    </div>
                </div>
            </div>
            <div
                class="dropdown-item"
                on:click={() => {
                    menuToggleHandler(dropdownTitle.toLowerCase(), false);
                    currentTool = "SCALE";
                }}
                on:mouseover={() => {
                    // trashOptionsToggle = false;
                    wallOptionsToggle = false;
                }}
                on:focus
            >
                Set Scale
            </div>
        {:else}
            <div style="color: lightgray;">propagating...</div>
        {/if}
    </div>
</main>

<!-- <div
            class="dropdown-item"
            on:click={() => {
                menuToggleHandler(dropdownTitle.toLowerCase(), false);
                propagateButtonHandler();
            }}
            on:mouseover={() => {
                trashOptionsToggle = false;
                wallOptionsToggle = false;
            }}
            on:focus
        >
            <div class="propagate-text">Propagate</div>
        </div> -->

<!-- <div
            class="dropdown-item"
            on:click={() => {
                menuToggleHandler(dropdownTitle.toLowerCase(), false);
                const canvas = document.getElementById("canvas");
                const canvasImage = canvas.toDataURL("image/png");
                const link = document.createElement("a");
                link.href = canvasImage;
                link.download = "canvasImageTest.png";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }}
            on:mouseover={() => {
                trashOptionsToggle = false;
                wallOptionsToggle = false;
            }}
            on:focus
        >
            <div class="more-options-item dropdown-item">DL Page</div>
        </div> -->
<style>
    .dropdown-menu {
        position: absolute;
        /* height: fit-content; */
        width: 8rem;
        background-color: #252321;
        border-radius: 9px;
        color: white;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    #dropdown {
        /* margin-left: 28vw; */
        margin-top: 4rem;
    }

    .dropdown-item {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
        justify-content: center;
        transition: 0.1s;
    }

    .dropdown-item:hover {
        color: gray;
        cursor: pointer;
        transition: 0.1s;
    }

    .options {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .more-options {
        position: absolute;
        background-color: #252321;
        width: 8rem;
        height: fit-content;
        margin-left: 8rem;
        margin-top: -1.75rem;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
        transition: 0.1s;
    }

    .more-options-item:hover {
        color: gray;
        cursor: pointer;
        transition: 0.1s;
    }

    .wall-desc {
        margin-left: 0.25rem;
    }

    .wall-point {
        height: 0.75rem;
        width: 0.75rem;
        /* border-radius: 4px; */
        border: 1px solid white;
        margin-right: 0.25rem;
    }

    .wall-type-wrapper {
        display: flex;
        width: 10rem;
        align-items: center;
    }
</style>

<script>
    // mkjr4,k09mgdssssssss r3awrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrriueujjj

    import {
        savingData,
    } from "../../../../../stores";

    export let currentFrequency;
    export let frequenciesList;
    export let deleteFreqHandler;

    const handleSettingFrequency = (event) => {
        const formData = new FormData(event.target);
        const [key, _] = formData;
        const newFrequency = Number(key[1]);
        const freqs = frequenciesList.map((x) => x.freq);
        !freqs.includes(newFrequency) &&
            frequenciesList.push({ freq: newFrequency, isCurrent: false });
        frequenciesList = frequenciesList;
        savingData.set(true);
    };

    $: {
        frequenciesList.forEach((item) => {
            if (item.freq !== currentFrequency) {
                item.isCurrent = false;
            }
        });
    }
</script>

<main>
    <div style="display: flex; justify-content: center;">
        <div class="tool-settings-title">Frequency Settings</div>
    </div>
    <div id="freq-setting-wrapper">
        <div class="specific-setting">
            <div class="ts-subtitle">Add Frequency (MHz)</div>
            <div id="scale-box">
                <form on:submit|preventDefault={handleSettingFrequency}>
                    <input id="input" name="scale-input" type="text" />
                    <!-- <button id="set-button" type="submit">add</button> -->
                </form>
            </div>
        </div>
        <div>
            <div style="padding-bottom: 0.5rem;">
                <div style="font-size: 0.85rem;">Current Frequency:</div>
                <div style="font-weight: bold;">
                    {currentFrequency ? currentFrequency : "[no freq set]"} MHz
                </div>
            </div>
            <div style="font-size: 0.85rem;">Frequency List:</div>
            {#each frequenciesList as frequency}
                <div class="freq-wrapper">
                    <div
                        id="info-box-freq"
                        style={frequency.isCurrent &&
                            "background-color: #00d1ff; pointer-events: none;"}
                        on:click={() => {
                            currentFrequency = frequency.freq;
                            frequency.isCurrent = true;
                        }}
                    >
                        {frequency.freq} MHz
                    </div>
                    <div
                        class="delete-freq"
                        on:click={() => deleteFreqHandler(frequency.freq)}
                    >
                        X
                    </div>
                </div>
            {/each}
        </div>
    </div>
</main>

<style>
    .tool-settings-title {
        text-align: center;
        background-color: gray;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
        font-weight: bold;
    }
    #info-box-freq {
        background-color: gray;
        color: black;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }

    #info-box-freq:hover {
        color: white;
        transition: 0.2s;
    }

    .ts-subtitle {
        font-weight: bold;
    }

    .specific-setting {
        padding-top: 0.5rem;
    }

    #scale-box {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    #freq-setting-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: 30vh;
        /* height: 100%; */
    }

    #freq-setting-wrapper::-webkit-scrollbar {
        width: 6px; /* width of the entire scrollbar */
    }

    #freq-setting-wrapper::-webkit-scrollbar-track {
        background: transparent; /* color of the tracking area */
    }

    #freq-setting-wrapper::-webkit-scrollbar-thumb {
        background-color: #00d1ff; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid transparent; /* creates padding around scroll thumb */
    }

    .freq-wrapper {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
    }

    .delete-freq {
        font-size: 0.75rem;
        color: gray;
        cursor: pointer;
        margin-left: 0.5rem;
        transition: 0.4s;
    }

    .delete-freq:hover {
        color: red;
        transition: 0.4s;
    }
</style>

<script>
    import { currentPageIndex } from "../../../../stores";
    export let totalNumPages;
    export let pageMetadata;
    export let isPropagating;

    let pagesLoaded = false;

    $: {
        pagesLoaded = totalNumPages > 0 ? true : false;
    }
</script>

<main>
    <div id="floor-plan-bar" class="overlay">
        <div class="item-wrapper">
            {#each Array(totalNumPages) as _, num}
                <div
                    class="page-item"
                    on:click={() =>
                        !isPropagating && currentPageIndex.set(Number(num))}
                    style={num === 0 && "border-left: 1px solid gray;"}
                >
                    <div
                        class="page-text"
                        style={num === Number($currentPageIndex) &&
                            "color: white;"}
                    >
                        {pagesLoaded && pageMetadata[num].name
                            ? pageMetadata[num].name
                            : `Page ${num}`}
                    </div>
                </div>
            {/each}
        </div>
        <div class="control-buttons">
            <div
                class="arrow"
                id="left-arrow"
                on:click={() => {
                    !isPropagating &&
                        $currentPageIndex > 0 &&
                        currentPageIndex.set(Number($currentPageIndex) - 1);
                }}
            >
                ᐊ
            </div>
            <div
                class="arrow"
                on:click={() => {
                    !isPropagating &&
                        $currentPageIndex < totalNumPages - 1 &&
                        currentPageIndex.set(Number($currentPageIndex) + 1);
                }}
            >
                ᐅ
            </div>
        </div>
    </div>
</main>

<style>
    .overlay {
        position: absolute;
        top: 1vh;
        /* margin-top: 1rem;
        margin-left: 1rem; */
        /* margin-left: 1vw; */
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        /* text-align: center; */
        height: 40px;
        border-radius: 9px;
        padding-right: 1rem;
        padding-left: 1rem;
        width: 38vw;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
        color: gray;
    }
    #floor-plan-bar {
        background-color: #252321;
        display: flex;
    }

    .page-item {
        /* min-width: 6rem; */
        width: fit-content;
        height: 40px;
        /* max-width: 6rem; */
        /* padding-left: 0.5rem;
        padding-right: 0.5rem; */
        border-right: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.2s;
    }

    .page-item:hover {
        background-color: black;
        transition: 0.2s;
    }

    .control-buttons {
        border-left: 1px solid gray;
        height: 2.5rem;
        position: absolute;
        right: 1vw;
        /* bottom: 0.75rem; */
        display: flex;
        font-size: 1.25rem;
        align-items: center;
        background-color: #252321;
    }

    .arrow {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        transition: 0.2s;
    }

    .arrow:hover {
        color: white;
        transition: 0.2s;
        cursor: pointer;
    }

    #left-arrow {
        padding-left: 1vw;
    }

    .item-wrapper {
        display: flex;
        width: 32vw;
        overflow-x: auto;
        scrollbar-width: thin;
        overflow-y: hidden;
    }

    .page-text {
        padding-left: 1rem;
        padding-right: 1rem;
    }
</style>

<script>
    export let isPropagating;
    export let currentTool;
    export let wallType;
    export let allWallTypes;
    export let propagationStatus;
    export let socket;

    propagationStatus = "WORKING...";

    socket.on("totalTxNum", (arg) => {
        totalTxNum = Number(arg) + 1;
    });

    socket.on("losTxNum", (arg) => {
        losTxNum = Number(arg);
    });

    socket.on("prePlot", (arg) => {
        losTxNum += arg;
    });

    let totalTxNum = 1;
    let losTxNum = 0;

    let wallColor = "";
    let wallAtten = 0;
    $: {
        for (let wall of allWallTypes) {
            if (wallType == wall.name) {
                wallColor = wall.color;
                wallAtten = wall.attenuation;
            }
        }
    }
</script>

<main>
    <div
        id="status-wrapper"
        style={isPropagating
            ? "justify-content: left;"
            : "justify-content: center;"}
    >
        {#if isPropagating}
            <!-- <div class="background-animated"> -->
            <div
                style={`width: ${Math.floor(
                    (100 / totalTxNum) * losTxNum
                )}%; align-self: left;`}
                class="background-animated"
                id="the-actual-bar"
            >
                <div id="percentage">
                    {Math.floor((100 / totalTxNum) * losTxNum)}% {propagationStatus}
                </div>
            </div>
            <!-- </div> -->
        {/if}
        {#if !isPropagating}
            <div style="display: flex;">
                <div id="current-tool">{currentTool}</div>
                {#if currentTool == "WALL"}
                    <div
                        style="display: flex; justify-content: center; align-items: center;"
                    >
                        <div style="margin-left: 0.5rem; margin-right: 0.5rem;">
                            |
                        </div>
                        <div
                            class="wall-point"
                            style={`background-color: ${wallColor};`}
                        />
                        <div>
                            {wallType} ⇔ {wallAtten}dB
                        </div>
                    </div>
                {/if}
            </div>
        {/if}
    </div>
</main>
<!-- <div class="exporting-overlay">
    <div id="inner-pcl">
        <div>
            Exporting page {pageProgress + 1} out of {totalNumPages}
        </div>
    </div>
    <div id="loading-bar">
        <div
            id="the-actual-bar"
            style={`width: ${Math.floor(
                (100 / totalNumPages) * pageProgress
            )}%;`}
        >
            <img
                src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                alt="loading..."
            />
            <div id="percentage">
                {Math.floor((100 / totalNumPages) * pageProgress)}%
            </div>
        </div>
    </div>
</div> -->

<!-- .exporting-overlay {
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    backdrop-filter: blur(6px);
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#inner-pcl {
    display: flex;
    width: 12.5rem;
    justify-content: space-between;
    align-items: center;
}

#loading-bar img {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
}

#loading-bar {
    margin-top: 1rem;
    width: 15rem;
    height: 2.1rem;
    background-color: rgba(0, 0, 0, 25%);
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

#the-actual-bar {
    background-color: #00d1ff;
    height: 2rem;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

#percentage {
    margin-left: 0.5rem;
    font-weight: bold;
} -->
<style>
    #status-wrapper {
        top: 1vh;
        margin-right: 1vw;
        display: flex;
        align-items: center;
        position: absolute;
        right: 15vw;
        /* margin-top: 1rem; */
        height: 40px;
        min-width: 8rem;
        width: 14vw;
        /* margin-left: 78.5rem; */
        background-color: #252321;
        color: white;
        border-radius: 9px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    #status-wrapper img {
        width: 1.5rem;
        margin-right: 0.5rem;
        /* position: absolute; */
        /* margin-left: -8rem; */
    }

    #current-tool {
        font-weight: bold;
        text-align: center;
        color: white;
        padding-top: 0.15rem;
        /* width: 100%; */
    }

    .background-animated {
        border-radius: 10px;
        height: 40px;
        color: black;
        font-weight: bold;
        background: linear-gradient(
            to right,
            #6666ff,
            #0099ff,
            #00ff00,
            #ff3399,
            #6666ff
        );
        animation: rainbow_animation 6s ease-in-out infinite;
        background-size: 400% 100%;
    }

    #current-tool-label {
        position: absolute;
        /* margin-left: -8vw; */
        font-size: 1.5rem;
        /* background-color: red; */
        /* margin-bottom: 1rem; */
        top: 0.25rem;
        left: 0.5rem;
        margin-right: 1rem;
    }

    .rainbow_text_animated {
        /* font-size: 1.25rem; */
        font-weight: bold;
        /* background: linear-gradient(
            to right,
            #6666ff,
            #0099ff,
            #00ff00,
            #ff3399,
            #6666ff
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        animation: rainbow_animation 2s ease-in-out infinite;
        background-size: 400% 100%; */
        /* letter-spacing: 2px; */
    }

    @keyframes rainbow_animation {
        0%,
        100% {
            background-position: 0 0;
        }

        50% {
            background-position: 100% 0;
        }
    }

    .wall-point {
        height: 0.75rem;
        width: 0.75rem;
        border: 1px solid white;
        margin-right: 0.5rem;
        margin-top: 0.15rem;
    }

    #the-actual-bar {
        /* background-color: #00d1ff; */
        height: 100%;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }

    #percentage {
        margin-left: 0.5rem;
        font-weight: bold;
        color: white;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }
</style>

import {
    currentProjectName,
    userId,
    savingData,
    currentProjectId,
    currentPageIndex,
    currentProjectData,
} from "../stores"

export const calculateTotalCoaxLength = (coaxLocs, ratio) => {
    let length = 0;
    for (let coax of coaxLocs) {
        const a = Math.abs(coax.x1 - coax.x2);
        const b = Math.abs(coax.y1 - coax.y2);

        let currentCoaxPixelLength = Math.floor(Math.sqrt(a * a + b * b));

        let currentCoaxLengthInMeters = currentCoaxPixelLength * ratio;

        length += Math.floor(currentCoaxLengthInMeters);
    }
    return length;
};

export const drawFloorImageToContext = (
    img,
    ctx,
    scale,
    offset,
    transWidth,
    transHeight
) => {
    ctx.translate(transWidth / 2 + offset.x, transHeight / 2 + offset.y);
    ctx.clearRect(0, 0, transWidth, transHeight);

    const adjustedBaseImageWidth = img.width * scale;
    const adjustedBaseImageHeight = img.height * scale;

    ctx.drawImage(
        img,
        -adjustedBaseImageWidth / 2,
        -adjustedBaseImageHeight / 2,
        adjustedBaseImageWidth,
        adjustedBaseImageHeight
    );
};

export const drawHeatmapToContext = (
    hm,
    ctx,
    scale,
    left,
    top,
    width,
    height,
    alpha
) => {
    ctx.globalAlpha = alpha;
    ctx.drawImage(
        hm,
        left * scale,
        top * scale,
        width * scale,
        height * scale
    );
    ctx.globalAlpha = 1;
};

export const drawWallsToCanvasContext = (ctx, walls, scalingRaito) => {
    for (let wall of walls) {
        ctx.beginPath();
        ctx.moveTo(wall.x1 * scalingRaito, wall.y1 * scalingRaito);
        ctx.lineTo(wall.x2 * scalingRaito, wall.y2 * scalingRaito);
        ctx.lineWidth =
            scalingRaito > 1
                ? wall.width
                : wall.width / scalingRaito;
        ctx.strokeStyle = wall.isHover ? "#ff0000" : wall.strokeStyle;
        ctx.stroke();
    }
};

export const drawAntennasToCanvasContext = (ctx, antennas, scalingRatio) => {
    const antImage = new Image();

    antImage.src = "svg/antenna.svg";

    for (let antenna of antennas) {
        // ctx.beginPath();
        // ctx.lineWidth = scalingRatio > 1 ? 30 : 10 * scalingRatio;
        // ctx.arc(
        //     antenna.x * scalingRatio,
        //     antenna.y * scalingRatio,
        //     scalingRatio > 1 ? 10 : 4 / scalingRatio,
        //     0,
        //     2 * Math.PI
        // );
        // ctx.strokeStyle = antenna.isHover ? "#ff0000" : "black";
        // ctx.stroke();

        ctx.drawImage(antImage,
            antenna.x * scalingRatio - ((30 * scalingRatio)/2),
            antenna.y * scalingRatio - ((30 * scalingRatio)/2),
            30 * scalingRatio,
            30 * scalingRatio);
    }
};

export const drawBDAToCanvasContext = (ctx, BDAs, scalingRatio) => {
    const bdaImage = new Image();

    bdaImage.src = "svg/BDA.svg";

    for (let bda of BDAs) {
        ctx.drawImage(bdaImage,
            bda.x * scalingRatio - ((30 * scalingRatio)/2),
            bda.y * scalingRatio - ((30 * scalingRatio)/2),
            30 * scalingRatio,
            30 * scalingRatio);
    }
};

export const drawSplittersToCanvasContext = (ctx, splitters, scalingRatio) => {
    const splitterImage = new Image();

    splitterImage.src = "svg/SPLITTER.svg";

    for (let splitter of splitters) {
        ctx.drawImage(splitterImage,
            splitter.x * scalingRatio - ((30 * scalingRatio)/2),
            splitter.y * scalingRatio - ((30 * scalingRatio)/2),
            30 * scalingRatio,
            30 * scalingRatio);
    }
};

export const drawRiserToCanvasContext = (ctx, riser, scalingRatio) => {
    const riserImage = new Image();

    riserImage.src = "svg/RISER.svg";

    riser = riser[0] ? riser[0] : {};

        ctx.drawImage(riserImage,
            riser.x * scalingRatio - ((30 * scalingRatio)/2),
            riser.y * scalingRatio - ((30 * scalingRatio)/2),
            30 * scalingRatio,
            30 * scalingRatio);
};


export const drawCablesToCanvasContext = (ctx, cables, scalingRatio, scaleRatio) => { // fix variable names bruh
    for (let cable of cables) {
        ctx.beginPath();
        ctx.moveTo(cable.x1 * scalingRatio, cable.y1 * scalingRatio);
        ctx.lineTo(cable.x2 * scalingRatio, cable.y2 * scalingRatio);
        ctx.lineWidth = 10;
        ctx.strokeStyle = cable.isHover ? "#ff0000" : "#B15252";
        let lineLength = 0;

            lineLength = (lineDistanceInPixels(cable) * scaleRatio).toFixed(1);
            ctx.font = `${scalingRatio > 1 ? 60 : 16 / scalingRatio}px Arial`;

            ctx.fillText(
                lineLength + "m",
                (cable.x1 * scalingRatio + cable.x2 * scalingRatio) /
                    2,
                (cable.y1 * scalingRatio + cable.y2 * scalingRatio) /
                    2
            );
        ctx.stroke();
    }
};

export const getEventLocation = (e) => {
    if (e.touches && e.touches.length == 1) {
        return { x: e.touches[0].clientX, y: e.touches[0].clientY };
    } else if (e.clientX && e.clientY) {
        return { x: e.clientX, y: e.clientY };
    } else if (!e.clientX && e.clientY) {
        return { x: 0, y: e.clientY };
    } else {
        return { x: e.clientX, y: 0 };
    }
};

// export const getMousePosition = (event) => {
//     const xCanvasOffset = window.innerWidth / 2;
//     const yCanvasOffset = window.innerHeight / 2;

//     const xCanvasLocation = getEventLocation(event).x;
//     const yCanvasLocation = getEventLocation(event).y;

//     const xPanOffset = cameraOffset.x - xCanvasOffset;
//     const yPanOffset = cameraOffset.y - yCanvasOffset;

//     const xLocation =
//         (xCanvasLocation - xCanvasOffset) / cameraZoom - xPanOffset;
//     const yLocation =
//         (yCanvasLocation - yCanvasOffset) / cameraZoom - yPanOffset;

//     return { x: xLocation, y: yLocation };
// };

export const lineDistanceInPixels = (line) => {
    const a = Math.abs(line.x1 - line.x2);
    const b = Math.abs(line.y1 - line.y2);

    return Math.floor(Math.sqrt(a * a + b * b));
};

export const getHeatmapDimensions = (wallLocs) => {
    let allXCoordinates = wallLocs
        .map((wall) => [Math.round(wall.x1), Math.round(wall.x2)])
        .flat();
    let allYCoordinates = wallLocs
        .map((wall) => [Math.round(wall.y1), Math.round(wall.y2)])
        .flat();

    const left = Math.min(...allXCoordinates);
    const right = Math.max(...allXCoordinates);
    const top = Math.min(...allYCoordinates);
    const bottom = Math.max(...allYCoordinates);

    return {
        left: left,
        top: top,
        width: Math.abs(left - right),
        height: Math.abs(top - bottom),
    };
};
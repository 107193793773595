import { writable } from "svelte/store";

const isLoggedInLS = localStorage.getItem("isLoggedIn");
export const isLoggedIn = writable(isLoggedInLS);
isLoggedIn.subscribe((value) => {
    localStorage.setItem(
        "isLoggedIn",
        JSON.parse(value) === true ? true : false
    );
});

const userIdLS = localStorage.getItem("userId");
export const userId = writable(userIdLS);
userId.subscribe((value) => {
    localStorage.setItem("userId", value ? value : null);
});

const currentProjectNameLS = localStorage.getItem("currentProjectName");
export const currentProjectName = writable(currentProjectNameLS);
currentProjectName.subscribe((value) => {
    localStorage.setItem("currentProjectName", value ? value : "");
});

export const savingData = writable(false);
export const metadata = writable({});

const projectIsOpenLS = localStorage.getItem("projectIsOpen");

export const projectIsOpen = writable(projectIsOpenLS);

projectIsOpen.subscribe((value) => {
    localStorage.setItem(
        "projectIsOpen",
        JSON.parse(value) === true ? true : false
    );
});

const currentProjectIdLS = localStorage.getItem("currentProjectId");
export const currentProjectId = writable(currentProjectIdLS);

currentProjectId.subscribe((value) => {
    localStorage.setItem("currentProjectId", value ? value : null);
});

const currentPageIndexLS = localStorage.getItem("currentPageIndex");
export const currentPageIndex = writable(currentPageIndexLS);

currentPageIndex.subscribe((value) => {
    localStorage.setItem("currentPageIndex", value ? value : 0);
});

export const imageUrls = writable([]);

export const currentProjectData = writable({});

// const currentNodeAmountLS = localStorage.getItem("currentNodeAmount");
// export const currentNodeAmount = writable(currentNodeAmountLS);

// currentNodeAmount.subscribe((value) => {
//     localStorage.setItem("currentNodeAmount", value ? value : 1);
// });

// const currentResolutionLS = localStorage.getItem("currentResolution");
// export const currentResolution = writable(currentResolutionLS);

// currentResolution.subscribe((value) => {
//     localStorage.setItem("currentResolution", value ? value : "25%");
// });

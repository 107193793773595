<script>
    let slideDots = ["⦾", "○", "○", "○", "○", "○", "○", "○"];

    export let currentSlide;

    $: {
        let temp = [];
        for (let i = 0; i < slideDots.length; i++) {
            if (i == currentSlide) {
                temp.push("⦾");
            } else temp.push("○");
        }
        slideDots = temp;
    }
</script>

<main>
    <div id="slide-progress-wrapper">
        <div
            class="slide-carrot no-select"
            on:click={() => {
                if (currentSlide > 0) currentSlide -= 1;
            }}
        >
            {"<"}
        </div>
        <div id="slide-dots">
            {#each slideDots as dot}
                {#if dot == "•"}
                    <div class="slide-dot no-select" id="filled-in-dot">
                        {dot}
                    </div>
                {:else}
                    <div class="slide-dot no-select">{dot}</div>
                {/if}
            {/each}
        </div>
        <div
            class="slide-carrot no-select"
            on:click={() => {
                if (currentSlide < slideDots.length - 1) currentSlide += 1;
            }}
        >
            {">"}
        </div>
    </div>
</main>

<style>
    #slide-progress-wrapper {
        /* position: absolute; */
        display: flex;
        font-weight: bold;
        color: white;
        justify-content: center;
        /* bottom: 6vh; */
        /* margin-top: 1rem; */
    }

    .slide-dot {
        color: #00d1ff;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    #slide-dots {
        display: flex;
    }

    #filled-in-dot {
        font-size: 1.25rem;
        /* margin-bottom: 1rem; */
    }

    .slide-carrot {
        font-size: 1.25rem;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    .slide-carrot:hover {
        color: #00d1ff;
        transform: 0.2s;
    }

    .no-select {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
</style>

<script>
    export let errors;
</script>

<main>
    <div id="error-wrapper">
        <div id="test">
            {#each errors as error}
                <div>{error}</div>
            {/each}
        </div>
    </div>
</main>

<style>
    #error-wrapper {
        display: flex;
        justify-content: center;
    }

    #test {
        text-align: center;
        color: red;
        position: absolute;
        /* height: 5rem; */
        width: 20rem;
        bottom: 4rem;
    }
</style>

import {
    currentProjectName,
    userId,
    savingData,
    currentProjectId,
    currentPageIndex,
    currentProjectData,
} from "../stores"

// export const saveCanvasImage = async () => {
//     const canvasImage = canvas && canvas.toDataURL("image/png");
//     const blob = await fetch(canvasImage).then((res) => res.blob());
//     // const file = new File([blob], `${$currentProjectName}`, {
//     //     type: "image/png",
//     //     lastModified: new Date(),
//     // });
//     const file = new File([blob], `${img}`, {
//         type: "image/png",
//         lastModified: new Date(),
//     });
//     const projectImageRef = storgRef(
//         storage,
//         `users/${$userId}/projects/${$currentProjectId}/thumbnail/${$currentProjectName}-thumbnail.png`
//     );
//     await uploadBytes(projectImageRef, file);
// };

export const downloadCanvasAsPNG = (cvs, page) => {
    const dateString = new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    const cvsImage = cvs.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = cvsImage;
    // link.download = `${$currentProjectName}-[${dateString}]-P${
    //     Number(page) + 1
    // }.png`;
    link.download = `${[]}-[${dateString}]-P${
        Number(page) + 1
    }.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const loadImage = (item) =>
    new Promise((resolve, reject) => {
        const floorImage = new Image();
        floorImage.crossOrigin = "anonymous";
        floorImage.src = item.url;
        floorImage.onload = () =>
            resolve({ img: floorImage, idx: item.idx });
    });

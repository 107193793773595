<script>
    // import ProfileSvg from "../../../../../public/svg/profileSVG.svelte";
    import LogOutSVG from "../../../../../public/svg/logoutSVG.svelte";
    import BackToMenuSvg from "../../../../../public/svg/backToMenuSVG.svelte";
    import { signOut, getAuth } from "firebase/auth";
    import {
        projectIsOpen,
        currentPageIndex,
        imageUrls,
        currentProjectData,
    } from "../../../../stores.js";
    const auth = getAuth();

    const setToClose = () => {
        projectIsOpen.set(false);
        currentPageIndex.set(0);
        imageUrls.set([]);
        window.location.reload();
    };
</script>

<main>
    <div class="overlay" id="profile-bar">
        <!-- <div id="profile-icon">
            <ProfileSvg />
        </div> -->
        <div id="back-to-menu" on:click={setToClose}>
            <div class="icon-and-label">
                <div class="menu-svg">
                    <BackToMenuSvg />
                </div>
                <div class="text">Menu</div>
            </div>
        </div>
        <div
            id="logout"
            on:click={() => {
                setToClose();
                signOut(auth);
            }}
        >
            <div class="icon-and-label">
                <div class="logout-svg">
                    <LogOutSVG />
                </div>
                <div class="text">Log out</div>
            </div>
        </div>
    </div>
</main>

<style>
    .overlay {
        position: absolute;
        /* margin-top: 1rem;
        margin-left: 1rem; */
        top: 1vh;
        right: 1vw;
        height: 40px;
        border-radius: 9px;
        /* padding-right: 1rem;
        padding-left: 1rem; */
        width: 14vw;
        min-width: 10rem;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    #profile-bar {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: #252321;
    }

    #back-to-menu {
        /* width: 4rem; */
        cursor: pointer;
        /* background-color: red; */
    }

    .logout-svg {
        width: 1.5rem;
        cursor: pointer;
    }

    .icon-and-label {
        display: flex;
        align-items: center;
        color: white;
    }

    .menu-svg {
        width: 1.25rem;
    }

    .text {
        margin-left: 0.5rem;
        font-size: 0.75rem;
        font-weight: bold;
        color: gray;
    }
</style>

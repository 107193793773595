<script>
    import SlideDots from "./SlideDots.svelte";
    import TutorialPage from "./TutorialPage.svelte";
    let heatmap = "images/heatmapexample.PNG";
    export let toggle;

    let currentSlide = 0;
    let componentArray = [];
</script>

<main>
    <div id="instructions-wrapper">
        <div id="inner-box">
            <div on:click={() => (toggle = false)} id="close-button">X</div>
            <TutorialPage bind:currentSlide />
            <SlideDots bind:currentSlide />
        </div>
    </div>
</main>

<style>
    #instructions-wrapper {
        display: flex;
        justify-content: center;
        /* align-content: center; */
        align-items: center;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        z-index: 998;
    }

    #inner-box {
        /* position: absolute; */
        /* margin-top: 1rem; */
        width: 50rem;
        height: 40rem;
        background-color: #252321;
        border-radius: 10px;
        z-index: 999;
    }

    #close-button {
        position: absolute;
        margin-left: 0.5rem;
        margin-top: 0.5rem;
        color: white;
        cursor: pointer;
        width: fit-content;
        font-weight: bolder;
        transition: 0.2s;
    }

    #close-button:hover {
        color: #00d1ff;
        transition: 0.2s;
    }
</style>

<script>
    const designRFlogo = "icons/DRF-logo.svg";
    import { currentProjectName } from "../../../../stores.js";
</script>

<main>
    <div class="overlay" id="project-label">
        <!-- <div> -->
        <!-- <div id="back" on:click={() => projectIsOpen.set(false)}>
            BACK TO MENU
        </div> -->
        <img src={designRFlogo} alt="designRF logo" id="drf-logo" />
        <div id="current-tool">{$currentProjectName}</div>
        <!-- </div> -->
    </div>
</main>

<style>
    #project-label {
        top: 1vh;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14vw;
        min-width: 10rem;
        background-color: #252321;
        left: 1vw;
    }

    #current-tool {
        font-weight: bold;
        color: white;
        /* width: 100%; */
        /* margin-left: -0.5rem; */
    }

    #drf-logo {
        /* position: fixed; */
        height: 1.75rem;
        /* margin-left: -3rem; */
        width: 1rem;
        /* margin-top: -0.25rem; */
        margin-right: 0.5rem;
    }

    .overlay {
        /* position: absolute; */
        /* margin-top: 1rem;
        margin-left: 1rem; */
        height: 40px;
        border-radius: 9px;
        padding-right: 1rem;
        padding-left: 1rem;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
</style>

<script>
    export let title;
    export let defaultInfo;
    export let info;
    export let extraStyle;
</script>

<main>
    <div class="profile-item">
        <div class="profile-item-title">{title}</div>
        {#if !info}
            <div>{defaultInfo}</div>
        {:else}
            <div class="profile-info" style={extraStyle}>
                {info}
            </div>
        {/if}
    </div>
</main>

<style>
    .profile-item {
        display: flex;
        padding-bottom: 1rem;
    }

    .profile-info {
        font-weight: lighter;
        color: #00d1ff;
    }

    .profile-item-title {
        font-weight: bold;
        padding-right: 0.5rem;
    }
</style>

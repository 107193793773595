<script>
</script>

<main>
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        version="1.1"
        width="32"
        height="32"
        viewbox="0 0 72 72"
        xml:space="preserve"
        id="svg30"
        sodipodi:docname="noun_1327109_cc.svg"
        inkscape:version="0.92.2 5c3e80d, 2017-08-06"
        ><metadata id="metadata36"
            ><rdf:RDF
                ><cc:Work rdf:about=""
                    ><dc:format>image/svg+xml</dc:format><dc:type
                        rdf:resource="http://purl.org/dc/dcmitype/StillImage"
                    /><dc:title /></cc:Work
                ></rdf:RDF
            ></metadata
        ><defs id="defs34" /><sodipodi:namedview
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1"
            objecttolerance="10"
            gridtolerance="10"
            guidetolerance="10"
            inkscape:pageopacity="0"
            inkscape:pageshadow="2"
            inkscape:window-width="725"
            inkscape:window-height="480"
            id="namedview32"
            showgrid="false"
            inkscape:zoom="2.7764706"
            inkscape:cx="34"
            inkscape:cy="42.5"
            inkscape:window-x="0"
            inkscape:window-y="0"
            inkscape:window-maximized="0"
            inkscape:current-layer="svg30"
        /><style type="text/css" id="style2">
        </style><g id="g24" transform="translate(3.9618644,4.7669492)"
            ><g id="g6"
                ><path
                    class="st0"
                    d="M 63.7,8 H 32.6 l -3,-4.6 c 0,0 0,-0.1 -0.1,-0.1 C 28.2,1.8 26.4,1 24.4,1 H 3.1 C 1.9,1 1,1.9 1,3.1 v 45.4 c 0,1.8 1.5,3.2 3.3,3.2 h 19.1 c 0.6,0 1,-0.4 1,-1 0,-0.6 -0.4,-1 -1,-1 H 4.3 C 3.6,49.7 3,49.2 3,48.5 V 19.7 h 62 v 28.8 c 0,0.7 -0.6,1.2 -1.3,1.2 h -1.8 c -0.6,0 -1,0.4 -1,1 0,0.6 0.4,1 1,1 h 1.8 c 1.8,0 3.3,-1.4 3.3,-3.2 V 11.3 C 67,10 65.6,8 63.7,8 Z M 3,17.7 V 3.1 C 3,3 3,3 3.1,3 h 21.3 c 1.4,0 2.7,0.6 3.5,1.6 l 3.3,5 C 31.4,9.9 31.7,10 32,10 h 31.6 c 0.7,0 1.3,0.9 1.3,1.2 v 6.5 z"
                    id="path4"
                    inkscape:connector-curvature="0"
                /></g
            ><g id="g10"
                ><path
                    class="st0"
                    d="M 51.3,42.8 62.1,33 c 0.7,-0.6 1.1,-1.4 1.1,-2.3 0,-0.9 -0.3,-1.8 -0.9,-2.4 L 59.5,25.2 C 58.2,23.8 56.1,23.7 54.7,25 l -4.6,4 c 0,0 -0.1,0 -0.1,0.1 L 43.5,35 32.3,23.6 c -0.4,-0.4 -1,-0.4 -1.4,0 L 20.5,33.8 c -0.2,0.2 -0.3,0.4 -0.3,0.7 0,0.3 0.1,0.5 0.3,0.7 l 10.8,10.9 -7.4,6.8 c 0,0 0,0 0,0 0,0 -0.2,0.2 -0.2,0.2 l -7,12.4 c -0.2,0.4 -0.2,0.8 0.1,1.2 0.3,0.3 0.8,0.4 1.1,0.2 l 12.9,-5.7 c 0.1,0 0.3,-0.1 0.4,-0.2 0,0 0,0 0,0 0,0 0,0 0,0 L 39,53.9 c 0.7,0.7 12.4,12.6 12.4,12.6 0.2,0.2 0.5,0.3 0.7,0.3 0.3,0 0.5,-0.1 0.7,-0.3 L 63.2,56.3 c 0.2,-0.2 0.3,-0.4 0.3,-0.7 0,-0.3 -0.1,-0.5 -0.3,-0.7 z m -3.2,-3.2 5.6,-5 1.7,1.8 -5.5,5 z M 56,26.4 c 0.6,-0.5 1.4,-0.5 2,0.1 l 2.8,3.1 c 0.2,0.3 0.4,0.6 0.4,1 0,0.4 -0.2,0.7 -0.4,1 l -3.9,3.5 -2.3,-2.6 c 0,0 0,0 0,0 0,0 0,0 0,0 l -2.3,-2.6 z m -5.4,4.9 1.7,1.8 -5.6,5.1 -1.7,-1.8 z m -24.7,22.4 6.8,-6.2 1.7,1.7 -6.9,6.2 z m -1.2,1.6 4.2,4.6 -9,4 z m 5.9,3.5 -1.8,-2 6.9,-6.2 1.8,1.9 z m 21.6,5.6 -2.5,-2.5 5.8,-5.7 c 0.4,-0.4 0.4,-1 0,-1.4 -0.4,-0.4 -1,-0.4 -1.4,0 L 48.3,60.5 45.8,58 49,54.8 c 0.4,-0.4 0.4,-1 0,-1.4 -0.4,-0.4 -1,-0.4 -1.4,0 l -3.2,3.2 -2.4,-2.5 5.8,-5.7 c 0.4,-0.4 0.4,-1 0,-1.4 -0.4,-0.4 -1,-0.4 -1.4,0 l -5.8,5.7 -2.4,-2.5 3.2,-3.2 c 0.4,-0.4 0.4,-1 0,-1.4 -0.4,-0.4 -1,-0.4 -1.4,0 l -3.2,3.2 -2.4,-2.5 5.8,-5.7 c 0.4,-0.4 0.4,-1 0,-1.4 -0.4,-0.4 -1,-0.4 -1.4,0 l -5.8,5.7 -2.4,-2.5 3.2,-3.2 c 0.4,-0.4 0.4,-1 0,-1.4 -0.4,-0.4 -1,-0.4 -1.4,0 L 29,41 26.6,38.5 32.4,32.8 c 0.4,-0.4 0.4,-1 0,-1.4 C 32,31 31.4,31 31,31.4 l -5.8,5.7 -2.5,-2.6 8.9,-8.8 17.5,17.8 v 0 0 l 12,12.2 z"
                    id="path8"
                    inkscape:connector-curvature="0"
                /></g
            ><g id="g14"
                ><path
                    class="st0"
                    d="m 6.8,28.2 h 15.7 c 0.6,0 1,-0.4 1,-1 0,-0.6 -0.4,-1 -1,-1 H 6.8 c -0.6,0 -1,0.4 -1,1 0,0.5 0.5,1 1,1 z"
                    id="path12"
                    inkscape:connector-curvature="0"
                /></g
            ><g id="g18"
                ><path
                    class="st0"
                    d="m 6.8,36.1 h 10.7 c 0.6,0 1,-0.4 1,-1 0,-0.6 -0.4,-1 -1,-1 H 6.8 c -0.6,0 -1,0.4 -1,1 0,0.6 0.5,1 1,1 z"
                    id="path16"
                    inkscape:connector-curvature="0"
                /></g
            ><g id="g22"
                ><path
                    class="st0"
                    d="m 6.8,44.1 h 15.7 c 0.6,0 1,-0.4 1,-1 0,-0.6 -0.4,-1 -1,-1 H 6.8 c -0.6,0 -1,0.4 -1,1 0,0.5 0.5,1 1,1 z"
                    id="path20"
                    inkscape:connector-curvature="0"
                /></g
            ></g
        ></svg
    >
</main>

<style>
    svg {
        width: 2rem;
        height: 2rem;
    }
    main path {
        stroke: white;
        fill: white;
    }
    /* main:hover path {
        cursor: pointer;
        stroke: #00d1ff;
        fill: #00d1ff;
        transition: 0.2s;
    } */
</style>

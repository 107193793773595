<script>
    import MenuNav from "./MenuNav.svelte";
    import Projects from "./Projects/Projects.svelte";
    import Profile from "./Profile/Profile.svelte";

    const menuComponents = [Projects, Profile];

    let currentMenuComponentIdx = 0; // ProjectsPage
</script>

<main>
    <MenuNav bind:currentMenuComponentIdx />
    <svelte:component this={menuComponents[currentMenuComponentIdx]} />
</main>

<style>
    main {
        display: flex;
        background-color: #dbdbdb;
    }
</style>

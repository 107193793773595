<script>
    import ProjectItem from "./ProjectItem.svelte";
    import CreateProjectModal from "./CreateProject/CreateProjectModal.svelte";
    import OptionsModal from "./OptionsModal.svelte";
    import { getDatabase, ref, onValue } from "firebase/database";
    import { currentProjectName, userId } from "../../../stores";

    const db = getDatabase();
    const settingsToggle = {};

    let projects = [];
    let addProjectModalOpen = false;
    let optionsModalOpen = false;

    const projectsRef = ref(db, "users/" + $userId + "/projects");

    onValue(projectsRef, (snapshot) => {
        projects = [];
        const data = snapshot.val();
        if (data != null) {
            Object.keys(data).forEach((key) => {
                projects.push(data[key]);
            });
            projects = projects;
            projects.forEach(
                (proj) => (settingsToggle[proj.project_name] = false)
            );
        }
    });

    const settingsToggleHandler = (name, polarity) => {
        for (let key of Object.keys(settingsToggle)) {
            if (key == name) {
                settingsToggle[key] = polarity;
            } else settingsToggle[key] = false;
        }
    };
</script>

<main>
    <div id="menu-top-bar">
        <div id="title">Projects</div>
        <div id="add-project">
            <div id="add-icon" on:click={() => (addProjectModalOpen = true)}>
                +
            </div>
        </div>
    </div>
    {#if addProjectModalOpen}
        <CreateProjectModal {$userId} bind:addProjectModalOpen />
    {/if}
    {#if optionsModalOpen}
        <OptionsModal {$userId} bind:optionsModalOpen />
    {/if}

    <div id="projects-wrapper">
        {#each projects as project}
            <ProjectItem
                project={project}
                name={project.project_name}
                projectId={project.project_id}
                settingsToggled={settingsToggle[project.project_name]}
                {settingsToggleHandler}
                bind:optionsModalOpen
            />
        {/each}
    </div>
</main>

<style>
    main {
        width: 85%;
    }
    #menu-top-bar {
        background-color: #252321;
        height: 3.5rem;
        display: flex;
        align-items: center;
    }

    #title {
        padding-left: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: white;
    }

    #projects-wrapper {
        /* padding-left: 1rem;
        padding-top: 1rem; */
        display: flex;
        /* flex-wrap: wrap; */
        overflow: scroll;
        overflow-x: hidden;
        flex-direction: column;
        /* overflow-y: hidden; */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        height: 96vh;
        padding: 1rem;
        /* flex-wrap: wrap; */
    }

    #add-project {
        display: flex;
        color: white;
        align-items: center;
        position: absolute;
        right: 1rem;
        cursor: pointer;
    }

    #add-icon {
        font-size: 3rem;
        transition: 0.2s;
    }

    #add-icon:hover {
        color: #00d1ff;
        transition: 0.2s;
    }
</style>

<script>
    const kmLogo = "images/kms-blue-new.png";
    const drf = "images/designrf.png";
</script>

<main>
    <a href="https://www.kmswireless.com" target="_blank">
        <img class="logo image-wrapper" src={kmLogo} alt="k&m logo" />
    </a>
    <div>
        <div id="title" class="rainbow_text_animated">DesignRF</div>
        <!-- <img src={drf} class="drf" alt="designrf logo"/> -->
        <div id="sub-title">Propagation Software</div>
    </div>
</main>

<style>
    main {
        /* background-color: brown; */
        width: 50%;
        display: flex;
        justify-content: center;
        padding-top: 27.5%;
    }

    #sub-title {
        font-size: 1.5rem;
        font-weight: bold;
        color: #c4c4c4;
        padding-left: 0.15rem;
    }

    #title {
        font-weight: bold;
        font-size: 4rem;
    }

    .logo {
        height: 6rem;
    }

    .image-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        transition: 0.4s;
    }

    .image-wrapper:hover {
        height: 6.5rem;
        transition: 0.4s;
        cursor: pointer;
    }

    /* .drf {
        width: 20rem;
    } */
    .rainbow_text_animated {
        font-size: 1.25rem;
        font-weight: bold;
        background: linear-gradient(
            to right,
            #00d1ff,
            #00d1ff,
            #00d1ff,
            #00d1ff,
            #00d1ff,
            yellow,
            yellow,
            orange,
            orange,
            red,
            red,
            hotpink,
            hotpink,
            white,
            white,
            white,
            white,
            white
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        animation: rainbow_animation 20s ease-in infinite;
        background-size: 400% 100%;
        letter-spacing: 2px;
    }

    @keyframes rainbow_animation {
        0%,
        100% {
            background-position: 0 0;
        }

        50% {
            background-position: 100% 0;
        }
    }
</style>

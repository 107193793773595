<script>
    import { sendPasswordResetEmail, getAuth } from "firebase/auth";

    const auth = getAuth();
    let email = "";

    let statusThrown = false;
    let status = {};

    const resetErrors = () => {
        setTimeout(() => {
            statusThrown = false;
            status.message = "";
            status.type = "";
        }, 4000);
    };

    const forgotPasswordHandler = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                status.message = "Password reset link sent to email!";
                status.type = "success";
                statusThrown = true;
                resetErrors();
            })
            .catch((error) => {
                status.message = error.message;
                status.type = "error";
                statusThrown = true;
                resetErrors();
            });
    };
</script>

<main>
    <div id="login-wrapper">
        <div id="login-title">Reset Password</div>
        <form class="content" on:submit|preventDefault={forgotPasswordHandler}>
            <input
                type="text"
                placeholder="email"
                id="usr"
                bind:value={email}
            />
            <div style="display: flex;">
                <button id="submit-button" type="submit"> ></button>
                {#if statusThrown}
                    <div
                        id="pwd-error"
                        style={status.type == "error"
                            ? "color: red;"
                            : "color: #00d1ff"}
                    >
                        {status.message}
                    </div>
                {/if}
            </div>
        </form>
    </div>
</main>

<style>
    main {
        color: white;
        /* background-color: blue; */
        width: 50%;
        /* height: 50%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    input {
        border: none;
        background-image: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: 2.75rem;
        width: 20rem;
        border-radius: 10px;
        background-color: white;
        font-weight: bold;
        padding-left: 0.5rem;
    }

    ::placeholder {
        font-weight: bold;
        color: #c3c3c3;
    }

    ::-moz-placeholder {
        font-weight: bold;
        color: #c3c3c3;
    }

    #usr {
        margin-bottom: 0.5rem;
    }

    #login-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    .content {
        display: flex;
        flex-direction: column;
    }

    #submit-button {
        background-color: #00d1ff;
        color: black;
        border-radius: 10px;
        height: 1.5rem;
        width: 3rem;
        font-weight: bold;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        transition: 0.2s;
    }

    #submit-button:hover {
        background-color: aqua;
        transition: 0.2s;
        cursor: pointer;
    }

    #submit-button:active {
        background-color: whitesmoke;
    }

    #pwd-error {
        color: red;
        font-style: italic;
        font-size: 0.75rem;
        padding-top: 0.25rem;
        padding-left: 0.5rem;
    }
</style>

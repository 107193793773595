<script>
    import CompanyTitle from "./CompanyTitle.svelte";
    import Login from "./Login.svelte";
    import SignUp from "./SignUp.svelte";
    import ForgotPassword from "./ForgotPassword.svelte";

    let authComponents = [Login, SignUp, ForgotPassword];
    let currentAuthComponent = authComponents[0];
</script>

<main id="auth-wrapper">
    <div id="auth-block">
        <CompanyTitle />
        <svelte:component this={currentAuthComponent} />
        <div
            id="forgot-credentials"
            on:click={() => (currentAuthComponent = authComponents[2])}
            hidden={currentAuthComponent != authComponents[0]}
        >
            forgot password?
        </div>
        <!-- on:click={() => (currentAuthComponent = authComponents[1])} -->

        {#if currentAuthComponent == authComponents[0]}
            <div id="sign-up"
            on:click={() => (currentAuthComponent = authComponents[1])}
            >
                <div>Need an account? Click here to register</div>
            </div>
            {/if}
        {#if currentAuthComponent == authComponents[1]}
            <div
                id="sign-up"
                on:click={() => (currentAuthComponent = authComponents[0])}
            >
                Have an account? Click here to login
            </div>
        {/if}
        {#if currentAuthComponent == authComponents[2]}
            <div
                id="sign-up"
                on:click={() => (currentAuthComponent = authComponents[0])}
            >
                Back to login
            </div>
        {/if}
    </div>
</main>

<style>
    #auth-wrapper {
        display: flex;
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        background: #dbdbdb;
        justify-content: center;
        align-items: center;
    }
    #auth-block {
        background-color: #252321;
        /* background-color: black; */
        color: white;
        height: 40rem;
        width: 60rem;
        border-radius: 10px;
        display: flex;
        justify-content: space-evenly;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        position: relative;
    }

    #sign-up {
        position: absolute;
        color: #00d1ff;
        font-size: 0.75rem;
        font-style: italic;
        cursor: pointer;
        margin-bottom: 0.5rem;
        height: 1rem;
        bottom: 0;
        right: 0;
        margin-right: 0.5rem;
        transition: 0.2s;
    }

    #sign-up:hover {
        color: aqua;
        transition: 0.2s;
    }

    #forgot-credentials {
        position: absolute;
        color: #00d1ff;
        font-size: 0.75rem;
        font-style: italic;
        cursor: pointer;
        margin-bottom: 0.5rem;
        bottom: 14.5rem;
        right: 10rem;
        transition: 0.2s;
    }

    #forgot-credentials:hover {
        color: aqua;
        transition: 0.2s;
    }
</style>

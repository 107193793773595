<script>
    import {
        savingData
    } from '../../../../../stores'

    export let scaleLength;
    export let scaleLinePixelLength;
    export let scaleRatio;
    export let imageScale;
    // export let newImageScale;

    const handleSettingImageScale = (event) => {
        const formData = new FormData(event.target);
        const [key, _] = formData;
        imageScale = Number(key[1]) < 1 ? 1 : Number(key[1]);
        savingData.set(true)
    };

    const handleSettingScale = (event) => {
        const formData = new FormData(event.target);
        const [key, _] = formData;
        scaleLength = Number(key[1]) < 1 ? scaleLength = 1 : (Math.round(Number(key[1]) * 100) / 100);
        
        scaleRatio = scaleLength/ scaleLinePixelLength;

        savingData.set(true)
    };
</script>

<main>
    <div style="display: flex; justify-content: center;">
        <div class="tool-settings-title">Scale Settings</div>
    </div>
    <div id="scale-settings-wrapper">
        <div class="specific-setting">
            <div class="ts-subtitle">Set Scale (m)</div>
            <div id="scale-box">
                <form on:submit|preventDefault={handleSettingScale}>
                    <!-- <div style="display: flex;"> -->
                    <input id="input" name="scale-input" type="text" value={scaleLength}/>
                    <!-- <button id="set-button" type="submit">set</button> -->
                    <!-- </div> -->
                </form>
            </div>
            <div style="font-size: 0.85rem;">
                <div>Current Scale Length:</div>
                <div style="font-weight: bold;">{scaleLength}m</div>
            </div>
        </div>
        <div class="specific-setting">
            <div class="ts-subtitle">Set Image Scale</div>
            <div id="img-scale-box">
                <form on:submit|preventDefault={handleSettingImageScale}>
                    <!-- <div style="display: flex;"> -->
                    <input id="input" name="img-scale-input" type="text" value={imageScale}/>
                    <!-- <button id="set-button" type="submit">set</button> -->
                    <!-- </div> -->
                </form>
            </div>
            <div style="font-size: 0.85rem;">
                <div>Current Image Scale:</div>
                <div style="font-weight: bold;">{imageScale}x</div>
            </div>
        </div>
    </div>
</main>

<style>
    .tool-settings-title {
        text-align: center;
        background-color: gray;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
        font-weight: bold;
    }

    .ts-subtitle {
        font-weight: bold;
    }

    .specific-setting {
        padding-top: 0.5rem;
    }

    #scale-box {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    #scale-settings-wrapper {
        height: 14rem;
        overflow: scroll;
        overflow-x: hidden;
    }
</style>

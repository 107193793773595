<script>
    export let totalCoaxLength;
</script>

<main>
    <div style="display: flex; justify-content: center;">
        <div class="tool-settings-title">Cable Settings</div>
    </div>
    <div class="specific-setting">
        <div class="ts-subtitle">Total Cable Length:</div>
        <div>
            {totalCoaxLength}m
        </div>
    </div>
</main>

<style>
    .tool-settings-title {
        text-align: center;
        background-color: gray;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
        font-weight: bold;
    }

    .ts-subtitle {
        font-weight: bold;
    }

    .specific-setting {
        padding-top: 0.5rem;
    }
</style>

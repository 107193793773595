<script>
    import {
        getAuth,
        onAuthStateChanged,
        updateProfile,
        updateEmail,
    } from "firebase/auth";
    import EditSVG from "../../../../public/svg/editSVG.svelte";
    import ProfileItem from "./ProfileItem.svelte";
    import { userId } from "../../../stores";

    const auth = getAuth();
    const user = auth.currentUser;

    let profilePictureURL = "";
    let displayName = "";
    // let company = "";
    let email = "";
    let formattedDateJoined = "";
    let editMode = false;
    let emailError = false;

    let currentDisplayName = "";
    let currentEmail = "";
    if (user !== null) {
        // The user object has basic properties such as display name, email, etc.
        currentDisplayName = user.displayName;
        currentEmail = user.email;
        // const emailVerified = user.emailVerified;
    }

    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            userId.set(uid);

            profilePictureURL = user.photoURL;
            displayName = user.displayName;
            // company = user.company;
            email = user.email;
            formattedDateJoined = new Date(
                user.metadata.creationTime
            ).toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
            });
        } else {
            profilePictureURL = "";
            displayName = "";
            // company = "";
            email = "";
            formattedDateJoined = "";
        }
    });

    const handleUpdateProfile = () => {
        editMode = false;
        let emailChanged = false;
        let updateObj = {};
        if (displayName != currentDisplayName)
            updateObj.displayName = displayName;
        // if (company != currentCompany)
        //     updateObj.company = company;
        if (email != currentEmail) emailChanged = true;

        updateProfile(auth.currentUser, updateObj);

        if (emailChanged) {
            updateEmail(auth.currentUser, email).catch((error) => {
                emailError = true;
                email = "Something went wrong, please log in again";
            });
        }
    };
</script>

<main>
    <div id="menu-top-bar">
        <div id="title">Profile</div>
        <div id="edit-profile">
            <div id="edit-icon" on:click={() => (editMode = true)}>
                {#if !editMode}
                    <EditSVG />
                {/if}
            </div>
        </div>
    </div>
    <div id="profile-wrapper">
        {#if !editMode}
            <ProfileItem
                title={"Name:"}
                defaultInfo={"[Display Name]"}
                info={displayName}
            />
            <!-- <ProfileItem 
                title={"Company:"} 
                defaultInfo={"[Company]"} 
                info={company}
            /> -->
            <ProfileItem
                title={"Email:"}
                defaultInfo={"[Email]"}
                info={email}
                extraStyle={emailError && "color: red;"}
            />
            <ProfileItem
                title={"Member Since:"}
                defaultInfo={"[Date Joined]"}
                info={formattedDateJoined}
            />
        {:else}
            <form on:submit|preventDefault={handleUpdateProfile}>
                <div class="profile-item">
                    <div class="profile-item-title">Name:</div>
                    <input
                        type="text"
                        placeholder={displayName != "" && "Display Name"}
                        bind:value={displayName}
                    />
                </div>
                <!-- <div class="profile-item">
                    <div class="profile-item-title">Company:</div>
                    <input type="text" placeholder={company == "" ? "Company" : company} bind:value={company}/>
                </div> -->
                <div class="profile-item">
                    <div class="profile-item-title">Email:</div>
                    <input type="text" placeholder={email} bind:value={email} />
                    <div id="recent-login">(requires recent login)</div>
                </div>
                <button type="submit">Save</button>
            </form>
        {/if}
    </div>
</main>

<style>
    main {
        width: 85%;
    }
    #menu-top-bar {
        background-color: #252321;
        height: 3.5rem;
        display: flex;
        align-items: center;
    }

    #title {
        padding-left: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: white;
    }

    #profile-wrapper {
        padding-top: 1rem;
        padding-left: 1rem;
    }

    #edit-profile {
        display: flex;
        color: white;
        align-items: center;
        position: absolute;
        right: 1rem;
        cursor: pointer;
    }

    #edit-icon {
        padding-bottom: 0rem;
    }

    .profile-item {
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .profile-item-title {
        font-weight: bold;
        padding-right: 0.5rem;
    }

    #recent-login {
        padding-left: 0.5rem;
        font-size: 0.75rem;
        color: gray;
    }
</style>

<script>
    import ProjectSVG from "../../../public/svg/projectSVG.svelte";
    import ProfileSVG from "../../../public/svg/profileSVG.svelte";
    import { signOut, getAuth } from "firebase/auth";
    export let currentMenuComponentIdx;

    const auth = getAuth();
</script>

<main>
    <div>
        <div id="title">DesignRF</div>
        <div id="sub-title">Propagation Software</div>
    </div>
    <div id="menu-nav-wrapper">
        <div
            class="nav-item-wrapper"
            on:click={() => (currentMenuComponentIdx = 0)}
        >
            <div id="project-svg" class="nav-icon">
                <ProjectSVG />
            </div>
            <div class="nav-item">Projects</div>
        </div>
        <div
            class="nav-item-wrapper"
            on:click={() => (currentMenuComponentIdx = 1)}
        >
            <div id="profile-svg" class="nav-icon">
                <ProfileSVG />
            </div>
            <div class="nav-item" id="profile">Profile</div>
        </div>
    </div>
    <div
        id="log-out"
        on:click={async () => {
            await signOut(auth);
        }}
    >
        Log Out
    </div>
</main>

<style>
    main {
        background-color: #252321;
        height: 100vh;
        min-width: 18rem;
        width: 15%;
        /* -webkit-box-shadow: -1px 5px 8px 5px rgba(0, 0, 0, 0.58);
        box-shadow: -1px 5px 8px 5px rgba(0, 0, 0, 0.58); */
    }

    #title {
        color: white;
        font-weight: bold;
        font-size: 2rem;
        padding-left: 1rem;
        padding-top: 1rem;
    }

    #sub-title {
        color: gray;
        font-weight: bold;
        padding-left: 1rem;
    }

    .nav-item-wrapper {
        display: flex;
    }

    .nav-item {
        color: white;
        font-weight: bold;
        font-size: 1.25rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0.5rem;
        transition: 0.2s;
    }

    .nav-item:hover {
        cursor: pointer;
        color: #00d1ff;
        transition: 0.2s;
    }

    #project-svg {
        padding-top: 0.75rem;
        padding-left: 1rem;
    }

    #profile-svg {
        padding-top: 0.75rem;
        padding-left: 0.8rem;
    }

    #profile {
        padding-left: 0.3rem;
    }

    #log-out {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        font-size: 0.75rem;
        color: #00d1ff;
    }

    #log-out:hover {
        color: aqua;
        cursor: pointer;
    }
</style>

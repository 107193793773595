<script>
    import { onMount } from "svelte";
    import { getDatabase, ref, remove } from "firebase/database";
    import {
        getStorage,
        ref as storgRef,
        getDownloadURL,
        deleteObject,
        listAll,
    } from "firebase/storage";
    import {
        projectIsOpen,
        currentProjectName,
        userId,
        currentProjectId,
    } from "../../../stores";

    import PhotoAlt from "../../../../public/svg/photoAlt.svelte";

    console.log('wow!')

    export let settingsToggled;
    export let name;
    export let projectId;
    export let settingsToggleHandler;
    export let optionsModalOpen;

    let imageUrl;
    let isLoadingImage = false;
    let imageError = false;
    let deleteModalIsOpen = false;

    const db = getDatabase();
    const storage = getStorage();

    const projectThumbnailRef = storgRef(
        storage,
        `users/${$userId}/projects/${projectId}/thumbnail/${name}-thumbnail.png`
    );
    const projectPagesListRef = storgRef(
        storage,
        `users/${$userId}/projects/${projectId}/pages`
    );

    const projectHeatmapsListRef = storgRef(
        storage,
        `users/${$userId}/projects/${projectId}/heatmaps`
    );

    // onMount(() => {
    //     // isLoadingImage = true;
    //     // getDownloadURL(projectThumbnailRef)
    //     //     .then((url) => {
    //     //         imageUrl = url;
    //     //         isLoadingImage = false;
    //     //     })
    //     //     .catch((err) => {
    //     //         isLoadingImage = false;
    //     //         imageError = true;
    //     //     });

    //     listAll(projectPagesListRef).then((data) => {
    //         getDownloadURL(storgRef(storage, data.items[0]._location.path_))
    //             .then((url) => {
    //                 imageUrl = url;
    //                 isLoadingImage = false;
    //             })
    //             .catch((err) => {
    //                 isLoadingImage = false;
    //                 imageError = true;
    //             });
    //     });
    // });

    // oh god

    const openProjectHandler = () => {
        projectIsOpen.set(true);
        currentProjectName.set(name);
        currentProjectId.set(projectId);
    };

    const deleteAllPages = async (paths) => {
        for (let path of paths) {
            await deleteObject(storgRef(storage, path));
        }
    };

    const projectDropdownHandler = async (action) => {
        let pagePaths = [];
        let hmPaths = [];
        switch (action) {
            case "delete":
                // await deleteObject(projectThumbnailRef);
                await listAll(projectPagesListRef).then((pages) => {
                    pages.items.forEach((page) =>
                        pagePaths.push(page._location.path)
                    );
                });
                deleteAllPages(pagePaths);
                await listAll(projectHeatmapsListRef).then((heatmaps) => {
                    heatmaps.items.forEach((hm) => {
                        hmPaths.push(hm._location.path);
                    });
                });
                deleteAllPages(hmPaths);
                await remove(
                    ref(db, "users/" + $userId + "/projects" + `/${projectId}`)
                );
                settingsToggled = false;
                window.location.reload();
                break;
            case "options":
                currentProjectName.set(name);
                currentProjectId.set(projectId);
                settingsToggled = false;
                optionsModalOpen = true;
                break;
        }
    };
</script>

<main>
    <div id="project-item-wrapper">
        <div id="project-item-top-bar">
            <div id="title" on:click={openProjectHandler}>
                {name}
            </div>
            <div
                id="settings"
                class="no-select"
                on:click={() => {
                    currentProjectName.set(name);
                    currentProjectId.set(projectId);
                    settingsToggleHandler(name, settingsToggled ? false : true);
                }}
                style={settingsToggled ? "color: #00d1ff; font-size: 0.5rem;" : "color: black; font-size: 0.5rem;"}
            >
                ●●●
            </div>
            {#if settingsToggled}
                <div id="project-dropdown">
                    <div
                        id="project-dropdown-item"
                        on:click={() => projectDropdownHandler("options")}
                    >
                        Options
                    </div>
                    <div
                        id="project-dropdown-item"
                        on:click={() => (deleteModalIsOpen = true)}
                    >
                        Delete
                    </div>
                </div>
            {/if}
        </div>
        <!-- <div id="photo-wrapper" on:click={openProjectHandler}>
            <div id="photo-background">
                {#if isLoadingImage}
                    <img
                        src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                        alt="loading"
                        id="loading-photo"
                    />
                {:else if !imageError}
                    <img src={imageUrl} alt="" id="project-item-photo" />
                {:else}
                    <PhotoAlt />
                {/if}
            </div>
        </div> -->
    </div>
</main>
{#if deleteModalIsOpen}
    <div class="delete-modal">
        <div class="delete-modal-inner">
            <div>
                Are you sure you want to delete '{name}'?
            </div>
            <div style="display: flex;">
                <div
                    class="buttons"
                    on:click={() => (deleteModalIsOpen = false)}
                >
                    exit
                </div>
                <div
                    class="buttons"
                    id="del-button"
                    on:click={() => projectDropdownHandler("delete")}
                >
                    delete
                </div>
            </div>
        </div>
    </div>
{/if}

<style>
    #title {
        color: black;
        font-weight: bold;
        padding-left: 0.5rem;
    }

    #settings {
        color: white;
        padding-right: 0.5rem;
        cursor: pointer;
    }

    #project-item-wrapper {
        /* height: 14rem; */
        /* width: 18rem; */
        width: 100%;
        background-color: #c4c4c4;
        border-radius: 10px;
        /* border: 1px solid black; */
        margin-bottom: 1rem;
        position: relative;
        transition: 0.2s;
    }

    #project-item-wrapper:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 10px 13px -7px #000000,
            5px 5px 15px 5px rgba(0, 0, 0, 0);
        box-shadow: 0px 10px 13px -7px #000000,
            5px 5px 15px 5px rgba(0, 0, 0, 0);
        transition: 0.2s;
    }

    #project-item-top-bar {
        background-color: white;
        height: 3rem;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #photo-background {
        height: 10rem;
        width: 16rem;
        background-color: #dbdbdb;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #photo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80%;
    }

    #project-item-photo {
        height: 10rem;
        width: 16rem;
    }

    #loading-photo {
        width: 2rem;
    }

    #project-dropdown {
        z-index: 1000;
        width: 6rem;
        height: 4rem;
        background-color: #252321;
        color: white;
        border-radius: 10px;
        position: absolute;
        right: -3.75rem;
        top: 2rem;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    #project-dropdown-item {
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
    }

    #project-dropdown-item:hover {
        color: #00d1ff;
        transition: 0.2s;
    }

    /* #photo-alt {
        width: 2rem;
    } */

    .no-select {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }

    .delete-modal {
        position: absolute;
        height: 95%;
        width: 85%;
        background-color: red;
        z-index: 10001;
        background-color: rgba(0, 0, 0, 50%);
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    .delete-modal-inner {
        height: 16vh;
        width: 26vw;
        background-color: #252321;
        border-radius: 10px;
        color: white;
        display: flex;
        font-weight: bold;
        font-size: 1.25rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 12vh;
    }

    .buttons {
        margin: 1rem;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 10px;
        background-color: #c4c4c4;
        font-size: 1rem;
        color: black;
        transition: 0.2s;
    }

    .buttons:hover {
        background-color: #6a6a6a;
        color: white;
        transition: 0.2s;
    }

    #del-button {
        transition: 0.4s;
    }

    #del-button:hover {
        background-color: red;
        transition: 0.4s;
    }
</style>

<script>
    import DropDown from "../../../../../components/DropDown.svelte";
    import ToolsDropDown from "./ToolsDropDown.svelte";
    // import InfoSVG from "../../../../../../public/svg/info.svelte";
    import Instructions from "./Tutorial/Instructions.svelte";
    import { savingData, currentPageIndex } from "../../../../../stores";

    export let clearButtonHandler;
    export let currentTool;
    export let wallType;
    export let propagateButtonHandler;
    export let isPropagating;
    export let allWallTypes;
    export let exportCurrentPage;
    export let exportAll;

    let instructionsToggle = false;

    let dropdownToggle = {
        file: false,
        edit: false,
        tools: false,
    };

    const menuToggleHandler = (menu, isToggled) => {
        Object.keys(dropdownToggle).map((item) => {
            if (item === menu) {
                dropdownToggle[item] = isToggled;
            } else {
                dropdownToggle[item] = false;
            }
        });
    };

    // const downloadPage = () => {
    //     const dateString = new Date().toLocaleString("en-US", {
    //         hour12: false,
    //     });
    //     const canvas = document.getElementById("canvas");
    //     // const canvas = cloneCanvas(newCanvas);
    //     const canvasImage = canvas.toDataURL("image/png");
    //     const link = document.createElement("a");
    //     link.href = canvasImage;
    //     link.download = `${$currentProjectName}-[${dateString}].png`;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    // function cloneCanvas(oldCanvas) {
    //     //create a new canvas
    //     var newCanvas = document.createElement("canvas");
    //     var context = newCanvas.getContext("2d");

    //     //set dimensions
    //     newCanvas.width = oldCanvas.width;
    //     newCanvas.height = oldCanvas.height;
    //     // newCanvas.width = 5000;
    //     // newCanvas.height = 5000;

    //     //apply the old canvas to the new one
    //     context.drawImage(oldCanvas, 0, 0);

    //     //return the new canvas
    //     return newCanvas;
    // }
</script>

<main>
    <div
        style="display: flex; justify-content: space-around;"
        class="overlay"
        id="tool-overlay"
    >
        <div
            class="overlay-button"
            id="propagate-button"
            on:click={() => {
                dropdownToggle.file = dropdownToggle.file ? false : true;
                menuToggleHandler("file", dropdownToggle.file);
            }}
        >
            <div class="propagate-text">File</div>
        </div>
        <div
            class="overlay-button"
            id="propagate-button"
            on:click={() => {
                dropdownToggle.edit = dropdownToggle.edit ? false : true;
                menuToggleHandler("edit", dropdownToggle.edit);
            }}
        >
            <div class="propagate-text">Edit</div>
        </div>
        <div
            class="overlay-button"
            id="propagate-button"
            on:click={() => {
                dropdownToggle.tools = dropdownToggle.tools ? false : true;
                menuToggleHandler("tools", dropdownToggle.tools);
            }}
        >
            <div class="propagate-text">Tools</div>
        </div>
        <!-- <div
            class="overlay-button"
            id="info-button"
            on:click={() => {
                instructionsToggle = true;
            }}
        > -->
        <!-- <div class="propagate-text">INFO</div> -->
        <!-- <InfoSVG /> -->
        <!-- </div> -->
    </div>
    <!-- dropdownItems={["Save", "Save as...", "Export"]} -->
    {#if dropdownToggle.file}
        <DropDown
            dropdownItems={["Export page", "Save"]}
            dropdownTitle={"FILE"}
            {menuToggleHandler}
            itemFns={[
                () => exportCurrentPage($currentPageIndex),
                // exportAll,
                () => savingData.set(true),
            ]}
        />
    {/if}
    {#if dropdownToggle.edit}
        <!-- dropdownItems={["undo", "redo", "clear"]} -->
        <DropDown
            dropdownItems={["Clear"]}
            dropdownTitle={"EDIT"}
            {menuToggleHandler}
            itemFns={[clearButtonHandler]}
        />
    {/if}
    {#if dropdownToggle.tools}
        <ToolsDropDown
            dropdownTitle={"TOOLS"}
            {menuToggleHandler}
            {isPropagating}
            bind:currentTool
            bind:wallType
            {allWallTypes}
        />
    {/if}
    {#if instructionsToggle}
        <Instructions bind:toggle={instructionsToggle} />
    {/if}
</main>

<style>
    .overlay {
        top: 1vh;
        position: absolute;
        left: 15vw;
        height: 40px;
        border-radius: 9px;
        padding-right: 1rem;
        padding-left: 1rem;
        min-width: 8rem;
        width: 14vw;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    #tool-overlay {
        margin-left: 1vw;
        background-color: #252321;
    }

    .overlay-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        background-color: #252321;
    }

    .overlay-button:hover {
        cursor: pointer;
    }

    .propagate-text {
        color: white;
        font-size: 0.8rem;
        transition: 0.2s;
    }

    .propagate-text:hover {
        color: gray;
        transition: 0.2s;
    }

    #info-button {
        padding-top: 0.35rem;
        padding-left: 0.5rem;
    }
</style>

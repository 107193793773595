<script>
    import { currentProjectData } from "../../../../stores";
    let pagesLoaded = false;
    export let heatmap;
    export let showWallIds;
    export let showAntennaIds;
    export let showAntennas;
    export let showWalls;
    export let pageMetadata;
    export let heatmapToggle;
    export let showCables;
    const pageItemToggle = {};

    currentProjectData.subscribe((value) => {
        if (value.pages) {
            Object.keys(value.pages).forEach(
                (idx) => (pageItemToggle[idx] = false)
            );
            pagesLoaded = true;
        }
    });

    const pageToggleHandler = (pageNum, polarity, attr) => {
        if (!attr) {
            pageItemToggle[pageNum] = polarity;
        } else {
            pageItemToggle[pageNum] = { [attr]: polarity };
        }
    };

    const getAttrTitle = (attr) => {
        switch (attr) {
            case "wallLocations":
                return "Walls";
            case "antennaLocations":
                return "Antennas";
            case "heatmapUrl":
                return "Heatmap";
        }
    };

    let newHm = new Image();
</script>

<main>
    <div class="overlay" id="tool-settings-box">
        <div id="tool-settings-title">
            <div style="font-weight: bold;">ITEM TOGGLE</div>
        </div>
        <div class="data-wrapper">
            <div>
                <div class="id-toggle">
                    <div>Walls:</div>
                    <div class="specific-toggle">
                        <!-- <div 
                            style={showWallIds ? "color: #00d1ff;" : "color: gray;"}
                            class="toggle-item"
                            on:click={() => showWallIds = showWallIds ? false : true}
                            >
                                IDs
                        </div> -->
                        <div
                            style={showWalls
                                ? "color: #00d1ff;"
                                : "color: gray;"}
                            class="toggle-item"
                            on:click={() =>
                                (showWalls = showWalls ? false : true)}
                        >
                            ██
                        </div>
                    </div>
                </div>
                <div class="id-toggle">
                    <div>Antennas:</div>
                    <div
                        style={showAntennas
                            ? "color: #00d1ff;"
                            : "color: gray;"}
                        class="toggle-item"
                        on:click={() =>
                            (showAntennas = showAntennas ? false : true)}
                    >
                        ██
                    </div>
                    <!-- Item
                    <div class="specific-toggle">
                        <div
                            style={showAntennaIds
                                ? "color: #00d1ff;"
                                : "color: gray;"}
                            class="toggle-item"
                            on:click={() =>
                                (showAntennaIds = showAntennaIds
                                    ? false
                                    : true)}
                        >
                            IDs
                        </div>
                    </div> -->
                </div>
                <div class="id-toggle">
                    <div>Cables:</div>
                    <div
                        style={showCables ? "color: #00d1ff;" : "color: gray;"}
                        class="toggle-item"
                        on:click={() =>
                            (showCables = showCables ? false : true)}
                    >
                        ██
                    </div>
                </div>
                <div class="id-toggle">
                    <div>Heatmap:</div>
                    <div
                        style={heatmapToggle
                            ? "color: #00d1ff;"
                            : "color: gray;"}
                        class="toggle-item"
                        on:click={() =>
                            (heatmapToggle = heatmapToggle ? false : true)}
                    >
                        ██
                    </div>
                </div>
            </div>
        </div>
        <div class="parts-wrapper">
        {#if pagesLoaded}
            {#each Object.keys($currentProjectData.pages) as pageNum}
                <div class="page-item">
                    <div
                        class="page-item-title"
                        on:click={() =>
                            pageToggleHandler(
                                pageNum,
                                pageItemToggle[pageNum] ? false : true
                            )}
                    >
                        {Number(pageNum) + 1}{")"}
                        {pageMetadata &&
                            pageMetadata[pageNum] &&
                            pageMetadata[pageNum].name}
                    </div>
                    {#if pageItemToggle[pageNum]}
                        {#each Object.keys($currentProjectData.pages[pageNum]) as attr}
                            <div class="page-item-attr">
                                {#if attr == "wallLocations" || (attr == "antennaLocations" && attr != "heatmapUrl")}
                                    <div
                                        style="display: flex; justify-content: space-between; padding-right: 1rem;"
                                    >
                                        <div
                                            on:click={() =>
                                                pageToggleHandler(
                                                    pageNum,
                                                    pageItemToggle[pageNum][
                                                        attr
                                                    ]
                                                        ? false
                                                        : true,
                                                    attr
                                                )}
                                        >
                                            {getAttrTitle(attr)}
                                        </div>
                                        <!-- {#if attr == "wallLocations"}
                                        <input type="checkbox" bind:checked={isWallChecked} on:change={() => handleCheck(pageNum)}/>
                                    {/if} -->
                                        <!-- {#if attr == "antennaLocations"}
                                        <input type="checkbox" bind:checked={isAntennaChecked} on:change={() => handleCheck(pageNum)}/>
                                    {/if} -->
                                    </div>
                                    {#if pageItemToggle[pageNum][attr]}
                                        {#if attr == "heatmapUrl"}
                                            <div
                                                on:click={() => {
                                                    newHm.setAttribute(
                                                        "src",
                                                        $currentProjectData
                                                            .pages[pageNum][
                                                            attr
                                                        ]
                                                    );
                                                    heatmap = newHm;
                                                }}
                                                class="attr-item"
                                            >
                                                url: {$currentProjectData.pages[
                                                    pageNum
                                                ][attr]}
                                            </div>
                                        {:else}
                                            {#each Object.keys($currentProjectData.pages[pageNum][attr]) as item}
                                                <div class="attr-item">
                                                    {attr == "wallLocations"
                                                        ? "w" +
                                                          $currentProjectData
                                                              .pages[pageNum][
                                                              attr
                                                          ][item]._id
                                                        : "a" +
                                                          $currentProjectData
                                                              .pages[pageNum][
                                                              attr
                                                          ][item]._id}
                                                </div>
                                            {/each}
                                            <!-- {#each Object.keys($currentProjectData.pages[pageNum][attr]) as item} -->
                                        {/if}
                                    {/if}
                                {/if}
                            </div>
                        {/each}
                    {/if}
                </div>
            {/each}
        {/if}
    </div>
    </div>
    
</main>

<!-- {:else}
    {#if attr == "heatmapUrl"}
        {#if pageToggle[pageNum][attr]}
        <div class="attr-item">url: {$currentProjectData.pages[pageNum][attr]}</div>

        {/if}
    {/if} -->
<style>

    .parts-wrapper {
        overflow-y: scroll;
        height: 30vh;
    }

    .overlay {
        position: absolute;
        right: 1vw;
        /* bottom: 6vh; */
        bottom: 1rem;
        /* margin-top: 1rem;
        margin-left: 1rem; */
        height: 40px;
        border-radius: 9px;
        /* padding-right: 1rem; */
        /* padding-left: 1rem; */
        width: 12rem;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    #tool-settings-box {
        /* padding-top: 1rem;
        padding-left: 1rem; */
        /* margin-left: 91.5rem; */
        background-color: #252321;
        /* background-color: black; */
        /* margin-top: 8vh; */
        /* min-height: 9.25rem; */
        height: 45vh;
        width: 14vw;
        min-width: 10rem;
        color: white;
    }

    #tool-settings-title {
        display: flex;
        justify-content: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .data-wrapper {
        /* height: 38vh; */
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: thin;
        /* padding-left: 1rem; */
    }

    .page-item {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.5rem;
        border-top: 1px solid gray;
        padding-top: 0.5rem;
        text-indent: 1rem;
    }
    .page-item-title {
        font-size: 0.75rem;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }

    .page-item-title:hover {
        color: gray;
        transition: 0.2s;
    }

    .page-item-attr {
        margin-left: 1rem;
    }
    .attr-item {
        margin-left: 1rem;
    }

    .id-toggle {
        display: flex;
        padding-bottom: 0.5rem;
        text-indent: 1rem;
        justify-content: space-between;
    }

    .specific-toggle {
        display: flex;
        /* justify-content: space-between; */
        /* width: 8rem; */
        /* background-color: red; */
    }

    .toggle-item {
        cursor: pointer;
        transition: 0.2s;
        margin-right: 1rem;
    }
</style>

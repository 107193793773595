<main>
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 490 490"
        style="enable-background:new 0 0 490 490;"
        xml:space="preserve"
    >
        <g>
            <path
                d="M489.642,245c0-135.309-109.53-245-244.641-245S0.358,109.691,0.358,245s109.531,245,244.643,245
			S489.642,380.309,489.642,245z M149.487,176.57h199.895l-99.951,187.215L149.487,176.57z"
            />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
    </svg>
</main>

<style>
    main svg {
        width: 1.5rem;
        fill: #00d1ff;
        transition: 0.2s;
    }
    main svg:hover {
        transition: 0.2s;
        fill: #0d37ff;
        cursor: pointer;
    }
</style>

<script>
    export let currentNodeAmount;
    export let currentResolution;
    export let propagateButtonHandler;
    export let isPropagating;

    const nodesPerMeter = [1, 2, 3, 4, 5];
    const resolutionPercentages = ["25%", "50%", "75%", "FULL"];
</script>

<main>
    <div style="display: flex; justify-content: center;">
        <div class="tool-settings-title">Heatmap Settings</div>
    </div>
    <div class="specific-setting">
        <div class="ts-subtitle" style="margin-top: 0.5rem;">
            Nodes Per Meter
        </div>
        <div class="node-wrapper">
            {#each nodesPerMeter as nodeAmount}
                <div
                    class="node-block"
                    style={currentNodeAmount === nodeAmount &&
                        "background-color:  #00d1ff; font-weight: bold; pointer-events: none;"}
                    on:click={() => (currentNodeAmount = nodeAmount)}
                >
                    {nodeAmount}
                </div>
            {/each}
        </div>
        <div class="ts-subtitle" style="margin-top: 0.5rem;">
            Resolution Scaling
        </div>
        <div class="node-wrapper">
            {#each resolutionPercentages as resP}
                <div
                    class="res-block"
                    style={currentResolution == resP &&
                        "background-color:  #00d1ff; font-weight: bold; pointer-events: none;"}
                    on:click={() => (currentResolution = resP)}
                >
                    {resP}
                </div>
            {/each}
        </div>
        {#if !isPropagating}
            <div class="ts-subtitle">Generate Heatmap</div>
            <div on:click={propagateButtonHandler} id="propagate-text">
                Propagate
            </div>
        {:else}
            <div>propagating...</div>
        {/if}
    </div>
</main>

<style>
    .tool-settings-title {
        text-align: center;
        background-color: gray;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
        font-weight: bold;
    }

    #propagate-text {
        margin-top: 0.5rem;
        /* padding-top: 0.25rem; */
        color: black;
        cursor: pointer;
        font-weight: bold;
        background-color: gray;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 3vh;
        transition: 0.2s;
    }

    #propagate-text:hover {
        color: white;
        background-color: red;
        transition: 0.2s;
    }

    .ts-subtitle {
        font-weight: bold;
    }

    .specific-setting {
        padding-top: 0.5rem;
    }

    #scale-box {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .node-wrapper {
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .node-block {
        width: 100%;
        height: 1.5rem;
        background-color: gray;
        color: black;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        transition: 0.2s;
    }

    .res-block {
        width: 100%;
        height: 1.5rem;
        background-color: gray;
        color: black;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        transition: 0.2s;
    }

    .node-block:hover {
        color: white;
        border: 2px solid gray;
        transition: 0.2s;
        cursor: pointer;
    }

    .res-block:hover {
        color: white;
        border: 2px solid gray;
        transition: 0.2s;
        cursor: pointer;
    }
</style>

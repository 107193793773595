<script>
    export let currentSlide;
    let heatmap = "images/finished-heatmap.png";
    let walls = "gifs/walls-trimmed-better.gif";
    let antennas = "gifs/antenna-trimmed.gif";
    let coax = "gifs/coax-trimmed.gif";
    let scale = "gifs/scale-trimmed.gif";
    let freqs = "gifs/freqs-trimmed.gif";
    let propagate = "gifs/propagate-trimmed.gif";
    let dlPage = "gifs/dlpage-trimmed.gif";

    const tutorialData = [
        {
            title: "Tutorial - 0/7",
            subTitle: "K&M Systems Design Software",
            mainPicture: heatmap,
            descriptions: "Generate a heatmap from your design.",
        },
        {
            title: "Tutorial - 1/7",
            subTitle: "Place walls",
            mainPicture: walls,
            descriptions:
                "Choose between four different wall types (each with different attenuation). Click once to start the wall and click again to simultaneously place and start another wall. Press 'esc' to stop the continuation.",
        },
        {
            title: "Tutorial - 2/7",
            subTitle: "Place antennas",
            mainPicture: antennas,
            descriptions:
                "Place antennas at any location in the bounds of the walls drawn. Default output power is -10dBm.",
        },
        {
            title: "Tutorial - 3/7",
            subTitle: "Place cable",
            mainPicture: coax,
            descriptions:
                "Like drawing walls, place down cable (currently unnecessary for heatmap).",
        },
        {
            title: "Tutorial - 4/7",
            subTitle: "Set scale",
            mainPicture: scale,
            descriptions:
                "Select the scale tool to place down the reference line. Then, in the tool settings box on the right, select the scale tab and type in a number (reference line length in meters) pressing enter to set it.",
        },
        {
            title: "Tutorial - 5/7",
            subTitle: "Set frequency",
            mainPicture: freqs,
            descriptions:
                "Select the freqs tab and enter your preferred frequency to add it to the frequency list. From the list, select the frequency you'd like to use for the heatmap.",
        },
        {
            title: "Tutorial - 6/7",
            subTitle: "Generate heatmap!",
            mainPicture: propagate,
            descriptions:
                "From the tools dropdown menu, click the 'propagate' button to begin the heatmap generation. This may take a while!",
        },
        {
            title: "Tutorial - 7/7",
            subTitle: "Export heatmap to png",
            mainPicture: dlPage,
            descriptions:
                "To export the heatmap to a transparent png, click the 'DL Page' button from the tool dropdown.",
        },
    ];
</script>

<main>
    <div id="page-one-title">
        <div class="inst-title" id="tutorial">
            {tutorialData[currentSlide].title}
        </div>
        <div class="inst-title">{tutorialData[currentSlide].subTitle}</div>
    </div>
    <div id="tutorial-content-wrapper">
        <div class="pic-block">
            <img
                src={tutorialData[currentSlide].mainPicture}
                alt="heatmap"
                id="heatmap-img"
            />
        </div>
        <div id="descriptions">
            <div>{tutorialData[currentSlide].descriptions}</div>
        </div>
    </div>
</main>

<style>
    .inst-title {
        color: white;
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
    }

    #tutorial {
        font-size: 1.5rem;
        color: #00d1ff;
    }

    #page-one-title {
        margin-top: 1rem;
    }

    /* .pic-block {
        background-color: #dbdbdb;
        height: 22rem;
        width: 40rem;
        border-radius: 10px;
        display: flex;
        justify-content: center;
    } */

    #tutorial-content-wrapper {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #heatmap-img {
        margin-top: 0.5rem;
        height: 25rem;
        width: 40rem;
    }

    #descriptions {
        font-weight: bold;
        font-size: 1rem;
        margin-top: 1rem;
        color: white;
        /* background-color: whitesmoke; */
        height: 5rem;
        /* background-color: red; */
        width: 35rem;
        text-align: center;
    }
</style>

<script>
    import {
        savingData,
    } from "../../../../../stores";
    export let currentOutputPower;
    export let outputPowerList;
    export let deleteOutputPowerHandler;

    const handleSettingPower = (event) => {
        const formData = new FormData(event.target);
        const [key, _] = formData;
        const newPower = Number(key[1]);
        const powers = outputPowerList.map((x) => x.power);
        !powers.includes(newPower) &&
            outputPowerList.push({ power: newPower, isCurrent: false });
        outputPowerList = outputPowerList;

        savingData.set(true)
    };

    $: {
        outputPowerList.forEach((item) => {
            if (item.power !== currentOutputPower) {
                item.isCurrent = false;
            }
        });
    }
</script>

<main>
    <div style="display: flex; justify-content: center;">
        <div class="tool-settings-title">Antenna Settings</div>
    </div>
    <div id="power-setting-wrapper">
        <div class="specific-setting">
            <div class="ts-subtitle">Add Output Power (dBm)</div>
            <div id="scale-box">
                <form on:submit|preventDefault={handleSettingPower}>
                    <input id="input" name="scale-input" type="text" />
                    <!-- <button id="set-button" type="submit">add</button> -->
                </form>
            </div>
        </div>
        <div>
            <div style="padding-bottom: 0.5rem;">
                <div style="font-size: 0.85rem;">Current Power:</div>
                <div style="font-weight: bold;">
                    {currentOutputPower ? currentOutputPower : "[no power set]"}
                    dBm
                </div>
            </div>
            <div style="font-size: 0.85rem;">Power List:</div>
            {#each outputPowerList as power}
                <div class="freq-wrapper">
                    <div
                        id="info-box-freq"
                        style={power.isCurrent &&
                            "background-color: #00d1ff; pointer-events: none;"}
                        on:click={() => {
                            currentOutputPower = power.power == 0 ? 1 : power.power;
                            power.isCurrent = true;
                        }}
                    >
                        {power.power} dBm
                    </div>
                    <div
                        class="delete-freq"
                        on:click={() => deleteOutputPowerHandler(power.power)}
                    >
                        X
                    </div>
                </div>
            {/each}
        </div>
    </div>
</main>

<style>
    .tool-settings-title {
        text-align: center;
        background-color: gray;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
        font-weight: bold;
    }
    #info-box-freq {
        background-color: gray;
        color: black;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }

    #info-box-freq:hover {
        color: white;
        transition: 0.2s;
    }

    .ts-subtitle {
        font-weight: bold;
    }

    .specific-setting {
        padding-top: 0.5rem;
    }

    #scale-box {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    #power-setting-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: 30vh;
    }

    #power-setting-wrapper::-webkit-scrollbar {
        width: 6px; /* width of the entire scrollbar */
    }

    #power-setting-wrapper::-webkit-scrollbar-track {
        background: transparent; /* color of the tracking area */
    }

    #power-setting-wrapper::-webkit-scrollbar-thumb {
        background-color: #00d1ff; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid transparent; /* creates   padding around scroll thumb */
    }

    .freq-wrapper {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
    }

    .delete-freq {
        margin-left: 0.5rem;
        font-size: 0.75rem;
        color: gray;
        cursor: pointer;
        transition: 0.4s;
    }

    .delete-freq:hover {
        color: red;
        transition: 0.4s;
    }
</style>

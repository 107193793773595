<script>
    import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
    import { isLoggedIn } from "../../stores";

    const auth = getAuth();

    let email = "";
    let password = "";

    let signUpError = false;
    let errorMessage = "";

    const resetErrors = () => {
        setTimeout(() => {
            signUpError = false;
            errorMessage = "";
        }, 4000);
    };

    const submitForm = () => {
        if (!email.length || !password.length) {
            signUpError = true;
            errorMessage = "Forms cannot be blank!";
            resetErrors();
            return;
        }
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                isLoggedIn.set(true);
                const user = userCredential.user;
            })
            .catch((error) => {
                const errorCode = error.code;
                const erMessage = error.message;
                signUpError = true;
                //TODO: change error message from firebase default
                errorMessage = erMessage;
                resetErrors();
            });
    };
</script>

<main>
    <div id="login-wrapper">
        <!-- Site is currently under maintenance. Check back soon. -->
        <div id="login-title">Log In</div>
        <form class="content" on:submit|preventDefault={submitForm}>
            <input
                type="text"
                placeholder="email"
                id="usr"
                bind:value={email}
            />
            <input
                type="password"
                placeholder="password"
                id="pwd"
                bind:value={password}
            />
            <div style="display: flex;">
                <button id="submit-button" type="submit">➣</button>
                {#if signUpError}
                    <div id="pwd-error">{errorMessage}</div>
                {/if}
            </div>
        </form>
    </div>
</main>

<style>
    main {
        color: white;
        /* background-color: blue; */
        width: 50%;
        /* height: 50%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    input {
        border: none;
        background-image: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: 2.75rem;
        width: 20rem;
        border-radius: 10px;
        background-color: white;
        font-weight: bold;
        padding-left: 0.5rem;
    }

    ::placeholder {
        font-weight: bold;
        color: #c3c3c3;
    }

    ::-moz-placeholder {
        font-weight: bold;
        color: #c3c3c3;
    }

    #pwd {
        margin-bottom: 0.5rem;
    }

    #usr {
        margin-bottom: 1rem;
    }

    #login-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    .content {
        display: flex;
        flex-direction: column;
    }
    /* 
    #forgot-credentials {
        color: #00d1ff;
        font-size: 0.75rem;
        font-style: italic;
        cursor: pointer;
        margin-bottom: 0.5rem;
    }

    #forgot-credentials:hover {
        color: aqua;
        transition: 0.2s;
    } */

    #submit-button {
        background-color: #00d1ff;
        color: black;
        border-radius: 10px;
        height: 1.5rem;
        width: 3rem;
        font-weight: bold;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        transition: 0.2s;
    }

    #submit-button:hover {
        background-color: aqua;
        transition: 0.2s;
        cursor: pointer;
    }

    #submit-button:active {
        background-color: whitesmoke;
    }

    #pwd-error {
        color: red;
        font-style: italic;
        font-size: 0.75rem;
        padding-top: 0.25rem;
        padding-left: 0.5rem;
    }
</style>

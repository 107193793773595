<script>
</script>

<main>
    <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_2_49)">
            <circle cx="18.625" cy="10.875" r="5.125" stroke="white" />
            <path
                d="M20.1886 17.3637L22.6202 20.5225C23.3795 21.5089 22.6764 22.9375 21.4316 22.9375H16.5684C15.3236 22.9375 14.6205 21.5089 15.3798 20.5225L17.8114 17.3637C18.4118 16.5837 19.5882 16.5837 20.1886 17.3637Z"
                stroke="white"
            />
            <circle cx="19" cy="15" r="14.5" stroke="white" />
        </g>
        <defs>
            <filter
                id="filter0_d_2_49"
                x="0"
                y="0"
                width="38"
                height="38"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2_49"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2_49"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
</main>

<style>
</style>

<script>
    import { set, ref, getDatabase } from "firebase/database";
    import {
        getStorage,
        ref as storgRef,
        uploadBytes,
        getDownloadURL,
    } from "firebase/storage";
    import {
        currentProjectName,
        projectIsOpen,
        userId,
        currentProjectId,
        imageUrls,
    } from "../../../../stores";
    import DragNdrop from "../../../../../public/svg/dragNdrop.svelte";
    import FileItem from "./FileItem.svelte";
    import ProjectCreationOverlay from "./ProjectCreationOverlay.svelte";
    import { v4 as uuidv4 } from "uuid";

    const db = getDatabase();
    const storage = getStorage();

    let newProjectName;
    let newProjectId = uuidv4();
    let dragOverStyle =
        " cursor: pointer; -moz-box-shadow: inset 0 0 14px #00d1ff; -webkit-box-shadow: inset 0 0 14px #00d1ff; box-shadow: inset 0 0 14px #00d1ff; transition: 0.2s;";
    let isDraggingOver = false;
    let formattedFiles = [];
    let files = [];
    let filesDropped = false;
    let projectIsBeingCreated = false;
    let imageUploadCount = 0;

    const addProjectSubmitHandler = async () => {
        if (!newProjectName.length) {
            return;
        }
        projectIsBeingCreated = true;
        let storageRef;
        let metadata = {};
        for (let file of formattedFiles) {
            storageRef = storgRef(
                storage,
                `users/${$userId}/projects/${newProjectId}/pages/${file.idx}-${file.name}.png`
            );
            await uploadBytes(storageRef, file.item).then(async (page) => {
                let url = await getDownloadURL(
                    storgRef(storage, page.ref._location.path_)
                );
                $imageUrls = [...$imageUrls, url];
                imageUploadCount += 1;
            });
            metadata[file.idx] = {
                url: file.picUrl,
                name: file.name
                    ? file.name.split(" ").join("_").split("-").join("_")
                    : `Page_${Number(file.idx) + 1}`,
                idx: file.idx,
            };
        }

        await set(
            ref(db, "users/" + $userId + "/projects" + `/${newProjectId}`),
            {
                project_name: newProjectName,
                project_id: newProjectId,
                page_metadata: metadata,
                pages: metadata,
            }
        ).then(() => {
            currentProjectName.set(newProjectName);
            currentProjectId.set(newProjectId);
            projectIsOpen.set(true);
        });
    };

    const dropHandler = (e) => {
        isDraggingOver = false;
        Object.keys(e.dataTransfer.items).forEach((key) => {
            if (e.dataTransfer.items[key].getAsFile().type.includes("image")) {
                formattedFiles.push({
                    idx: key,
                    item: e.dataTransfer.items[key].getAsFile(),
                    picUrl: URL.createObjectURL(
                        e.dataTransfer.items[key].getAsFile()
                    ),
                    name: e.dataTransfer.items[key].getAsFile().name,
                });
            }
        });
        filesDropped = true;
    };

    const browseFilesHandler = () => {
        Object.keys(files).forEach((key) =>
            formattedFiles.push({
                idx: key,
                item: files[key],
                picUrl: URL.createObjectURL(files[key]),
                name: files[key].name,
            })
        );
        if (formattedFiles.length) {
            filesDropped = true;
        }
    };

    const fileControlHandler = (action, currentIdx) => {
        currentIdx = Number(currentIdx);
        switch (action) {
            case "up":
                if (formattedFiles[currentIdx - 1]) {
                    formattedFiles[currentIdx - 1].idx = currentIdx.toString();
                    formattedFiles[currentIdx].idx = (
                        currentIdx - 1
                    ).toString();
                }
                break;
            case "down":
                if (formattedFiles[currentIdx + 1]) {
                    formattedFiles[currentIdx + 1].idx = currentIdx.toString();
                    formattedFiles[currentIdx].idx = (
                        currentIdx + 1
                    ).toString();
                }
                break;
            case "delete":
                formattedFiles = formattedFiles.filter(
                    (file) => file.idx != currentIdx
                );
                formattedFiles.forEach(
                    (file) =>
                        (file.idx =
                            file.idx > currentIdx ? file.idx - 1 : file.idx)
                );
                break;
        }
        formattedFiles.sort((a, b) => a.idx - b.idx);
    };

    $: if (!formattedFiles.length) filesDropped = false;
</script>

<main>
    <div id="new-project-form">
        <div id="project-files-title">Add Project Files (image files only)</div>
        <div id="info-wrapper">
            {#if !filesDropped}
                <div id="add-file-wrapper">
                    <div
                        id="drop-zone"
                        on:drop|preventDefault={(e) => dropHandler(e)}
                        on:dragover|preventDefault={() =>
                            (isDraggingOver = true)}
                        on:dragleave={() => (isDraggingOver = false)}
                        style={isDraggingOver && dragOverStyle}
                    >
                        <DragNdrop />
                        <div id="drag-title">Drop project files here....</div>
                    </div>
                    <div id="file-input-div">- or -</div>
                    <input
                        type="file"
                        multiple
                        bind:files
                        on:change={browseFilesHandler}
                    />
                </div>
            {:else}
                <div id="dropped-zone">
                    {#each formattedFiles as file}
                        <FileItem
                            bind:file
                            {fileControlHandler}
                            fileUrl={file.picUrl}
                        />
                    {/each}
                </div>
            {/if}
            <form on:submit|preventDefault={addProjectSubmitHandler}>
                <div id="project-setting-item">
                    <div>Name:</div>
                    <input
                        placeholder="Project Name"
                        id="project-name"
                        bind:value={newProjectName}
                        class="name-input"
                    />
                </div>
                <button type="submit" class="create-button">Create</button>
            </form>
        </div>
    </div>
</main>

{#if projectIsBeingCreated}
    <ProjectCreationOverlay
        bind:imageUploadCount
        totalImages={formattedFiles.length}
    />
{/if}

<style>
    #project-name {
        margin-left: 1rem;
    }

    #new-project-form {
        /* margin-top: 2rem; */
        min-width: 30rem;
        width: 40vw;
    }

    #drop-zone {
        color: white;
        height: 6rem;
        width: 40vw;
        min-width: 20rem;
        background-color: #dbdbdb;
        border-radius: 10px;
        -moz-box-shadow: inset 0 0 10px #000000;
        -webkit-box-shadow: inset 0 0 10px #000000;
        box-shadow: inset 0 0 10px #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        /* cursor: pointer; */
    }

    #dropped-zone {
        color: white;
        height: 21rem;
        background-color: #dbdbdb;
        border-radius: 10px;
        -moz-box-shadow: inset 0 0 10px #000000;
        -webkit-box-shadow: inset 0 0 10px #000000;
        box-shadow: inset 0 0 10px #000000;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
    }

    #drag-title {
        color: gray;
        padding-left: 0.25rem;
        padding-bottom: 0.15rem;
    }

    #info-wrapper {
        color: white;
        display: flex;
        flex-direction: column;
    }

    #project-setting-item {
        display: flex;
        width: 10rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    #project-files-title {
        color: white;
        /* font-size: 1.5rem; */
        font-weight: bold;
        padding-bottom: 0.5rem;
    }

    #add-file-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input[type="file"] {
        width: 4rem;
        /* width: fit-content; */
        /* font-weight: bold; */
        /* font-size: 1.25rem; */
        color: transparent;
        cursor: pointer;
    }

    #file-input-div {
        margin: 1rem;
    }
    input[type="file"]::file-selector-button {
        /* background-color: gray; */
        /* font-size: 2rem; */
        color: white;
        background: none;
        /* color: inherit; */
        border: none;
        border-radius: 10px;
        padding: 0.5rem;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        transition: 0.2s;
    }

    input[type="file"]::file-selector-button:hover {
        color: #00d1ff;
        transition: 0.2s;
    }

    .name-input {
        border: none;
        background-image: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 20rem;
        height: 1.5rem;
        border-radius: 10px;
        background-color: white;
        font-weight: bold;
        padding-left: 0.5rem;
    }

    .create-button {
        cursor: pointer;
        padding: 0.25rem;
    }
</style>

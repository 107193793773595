<script>
    export let dropdownItems;
    export let dropdownTitle;
    export let menuToggleHandler;
    export let itemFns;

    let marginLeft;

    switch (dropdownTitle) {
        case "FILE":
            marginLeft = "18vw";
            break;
        case "EDIT":
            marginLeft = "22vw";
            break;
    }
</script>

<main>
    <div class="dropdown-menu" id="dropdown" style="margin-left: {marginLeft}">
        {#each dropdownItems as item}
            <div
                class="dropdown-item"
                on:click={() => {
                    let itemIndex = dropdownItems.indexOf(item);
                    menuToggleHandler(dropdownTitle.toLowerCase(), false);
                    itemFns[itemIndex]();
                }}
            >
                {item}
            </div>
        {/each}
    </div>
</main>

<style>
    .dropdown-menu {
        position: absolute;
        height: fit-content;
        width: 8rem;
        background-color: #252321;
        border-radius: 9px;
        color: white;
        padding: 10px;
    }

    #dropdown {
        /* margin-left: 12rem; */
        margin-top: 4rem;
    }

    .dropdown-item {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
        transition: 0.1s;
    }

    .dropdown-item:hover {
        color: gray;
        cursor: pointer;
        transition: 0.1s;
    }
</style>

<script>
    import { onMount } from "svelte";
    import Overlay from "../Overlay/Overlay.svelte";
    import { getDatabase, ref, update, onValue } from "firebase/database";
    import {
        getStorage,
        ref as storgRef,
        uploadBytes,
        listAll,
        getDownloadURL,
    } from "firebase/storage";
    
    import {
        userId,
        savingData,
        currentProjectId,
        currentPageIndex,
        currentProjectData,
    } from "../../../stores";

    import {
        calculateTotalCoaxLength,
        drawFloorImageToContext,
        drawHeatmapToContext,
        drawWallsToCanvasContext,
        drawAntennasToCanvasContext,
        drawCablesToCanvasContext,
        drawBDAToCanvasContext,
        getEventLocation,
        drawSplittersToCanvasContext,
        drawRiserToCanvasContext,
        // getMousePosition,
        lineDistanceInPixels,
        getHeatmapDimensions
    } from "../../../utils/canvas"

    import {
        // saveCanvasImage,
        downloadCanvasAsPNG,
        loadImage,
    } from "../../../utils/export"

    import {
        formatURLs,
        formatExportImage,
        formatFloorImage
    } from "../../../utils/format"

    export let socket;
    export let connectedToServer;

    // DON'T TOUCH!
    let canvas;
    let ctx;
    const MAX_ZOOM = 5;
    const MIN_ZOOM = 0.1;
    const SCROLL_SENSITIVITY = -0.0005;
    let cameraOffset = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    let cameraZoom = 0.25;
    let wallAttenuation = 5;
    let startPosition = { x: 0, y: 0 };
    let lineCoordinates = { x: 0, y: 0 };
    let isDrawStart = false;
    let isSettingScale = false;
    let scaleLinePixelLength = 0;
    let totalCoaxLength = 0;
    let scaleRatio = 0;

    let allCoaxCoordinates = [];
    let allWallCoordinates = [];
    let allAntennaCoordinates = [];
    let scaleLineCoordinates = [];

    let allBDACoordinates = [];
    let allSplitterCoordinates = [];
    let riserLocationCoordinates = [];

    let frequenciesList = [];
    let outputPowerList = [];
    let scaleLength = 0;

    let imageScale = 1;
    let totalNumPages = 0;
    let axisLocked = false;
    let showAntennaIds = false;
    let showWallIds = false;
    let showAntennas = true;
    let showWalls = true;
    let showCables = true;
    let pageMetadata = {};
    let allWallTypes = [];
    // let connectedToServer = false;
    let currentHeatmapUrl = "";
    let heatmapToggle = false;

    let isDragging = false;
    let dragStart = { x: 0, y: 0 };

    let currentTool = "WALL";
    let wallType = "";
    let wallStrokeStyle = "";
    let wallWidth = 0;
    let wallTypeId = "";

    let imgLeft, imgRight, imgTop, imgBottom, imgWidth, imgHeight;
    let heatmap = new Image();
    let urls = [];

    const db = getDatabase();
    const storage = getStorage();
    const projectImagesRef = storgRef(
        storage,
        `users/${$userId}/projects/${$currentProjectId}/pages`
    );

    const projectRef = ref(
        db,
        "users/" + $userId + "/projects/" + `${$currentProjectId}`
    );

    const heatmapImagesRef = storgRef(
        storage,
        `users/${$userId}/projects/${$currentProjectId}/heatmaps/`
    );

    const customWallsRef = ref(db, `users/${$userId}/custom/`);

    let newImage = new Image();

    currentPageIndex.subscribe(() => (heatmap = new Image()));

    ////////////////////////////////////////////////////////////////////////////////////////
    // HANDLING DATA (loading, processing, managing, saving)
    ////////////////////////////////////////////////////////////////////////////////////////

    onMount(async () => {
        newImage.crossOrigin = "anonymous";
        let pageItems = await listAll(projectImagesRef);
        let heatmapPictures = await listAll(heatmapImagesRef);

        urls = await formatURLs(pageItems, heatmapPictures, urls, storage);

        totalNumPages = urls.length;
        $currentPageIndex = Number($currentPageIndex);

        heatmapToggle = true;

        ctx = canvas.getContext("2d");
        draw();

        let allXCoordinates = allWallCoordinates
            .map((wall) => [Math.round(wall.x1), Math.round(wall.x2)])
            .flat();
        let allYCoordinates = allWallCoordinates
            .map((wall) => [Math.round(wall.y1), Math.round(wall.y2)])
            .flat();

        imgLeft = Math.min(...allXCoordinates);
        imgRight = Math.max(...allXCoordinates);
        imgTop = Math.min(...allYCoordinates);
        imgBottom = Math.max(...allYCoordinates);

        imgWidth = Math.abs(imgLeft - imgRight);
        imgHeight = Math.abs(imgTop - imgBottom);
    });

    // Database listeners

    onValue(customWallsRef, (snapshot) => {
        const data = snapshot.val();
        allWallTypes = data ? [...data.walls] : [];

        allWallTypes = allWallTypes;
    });

    onValue(projectRef, (snapshot) => {
        const data = snapshot.val();
        if (data != null) {
            const { txFrequencyList, txOutputPowerList, page_metadata } = data;

            currentProjectData.set(data);

            pageMetadata = page_metadata;

            if (
                $currentProjectData &&
                $currentProjectData.pages &&
                $currentProjectData.pages[$currentPageIndex]
            ) {
                let page = $currentProjectData.pages[$currentPageIndex];

                // let allBDACoordinates = [];
                // let allSplitterCoordinates = [];
                // let riserLocationCoordinates = [];
                let {
                    wallLocations,
                    antennaLocations,
                    calibratedWallPoints,
                    imageScaleFactor,
                    calWallPointsScale,
                    cableLocations,
                    heatmapUrl,
                    bdaLocations,
                    splitterLocations,
                    riserLocation
                } = page;

                allBDACoordinates = bdaLocations ? bdaLocations : [];
                allSplitterCoordinates = splitterLocations ? splitterLocations : [];
                riserLocationCoordinates = riserLocation ? riserLocation : [];
                allWallCoordinates = wallLocations ? wallLocations : [];
                allAntennaCoordinates = antennaLocations
                    ? antennaLocations
                    : [];
                scaleLineCoordinates = calibratedWallPoints
                    ? calibratedWallPoints
                    : [];
                imageScale = imageScaleFactor ? imageScaleFactor : 1;
                scaleLength = calWallPointsScale ? calWallPointsScale : 0;
                allCoaxCoordinates = cableLocations ? cableLocations : [];
                currentHeatmapUrl = heatmapUrl ? heatmapUrl : "";
                bdaLocations = bdaLocations ? allBDACoordinates : [];
                splitterLocations = splitterLocations ? allSplitterCoordinates : [];
                riserLocation = riserLocation ? riserLocationCoordinates : [];
            }

            frequenciesList = txFrequencyList ? txFrequencyList : [];
            outputPowerList = txOutputPowerList ? txOutputPowerList : [];
        }
    });

    currentPageIndex.subscribe((value) => {
        if (
            $currentProjectData &&
            $currentProjectData.pages &&
            $currentProjectData.pages[value]
        ) {
            let page = $currentProjectData.pages[value];
            let {
                wallLocations,
                antennaLocations,
                calibratedWallPoints,
                imageScaleFactor,
                calWallPointsScale,
                cableLocations,
                heatmapUrl,
                bdaLocations,
                splitterLocations,
                riserLocation
            } = page;

            allWallCoordinates = wallLocations ? wallLocations : [];
            allAntennaCoordinates = antennaLocations ? antennaLocations : [];
            scaleLineCoordinates = calibratedWallPoints
                ? calibratedWallPoints
                : [];
            imageScale = imageScaleFactor ? imageScaleFactor : 1;
            scaleLength = calWallPointsScale ? calWallPointsScale : 0;
            allCoaxCoordinates = cableLocations ? cableLocations : [];
            currentHeatmapUrl = heatmapUrl != "" ? heatmapUrl : "";
            allBDACoordinates = bdaLocations ? bdaLocations : [];
            allSplitterCoordinates = splitterLocations ? splitterLocations : [];
            riserLocationCoordinates = riserLocation ? riserLocation : [];

        } else {
            allWallCoordinates = [];
            allAntennaCoordinates = [];
            scaleLineCoordinates = [];
            allBDACoordinates = [];
            allSplitterCoordinates = [];
            riserLocationCoordinates = [];
            imageScale = 1;
            scaleLength = 0;
            allCoaxCoordinates = [];
            currentHeatmapUrl = "";
        }

        let allXCoordinates = allWallCoordinates
            .map((wall) => [Math.round(wall.x1), Math.round(wall.x2)])
            .flat();
        let allYCoordinates = allWallCoordinates
            .map((wall) => [Math.round(wall.y1), Math.round(wall.y2)])
            .flat();

        imgLeft = Math.min(...allXCoordinates);
        imgRight = Math.max(...allXCoordinates);
        imgTop = Math.min(...allYCoordinates);
        imgBottom = Math.max(...allYCoordinates);

        imgWidth = Math.abs(imgLeft - imgRight);
        imgHeight = Math.abs(imgTop - imgBottom);

        cameraOffset = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        cameraZoom = 0.25;
        startPosition = { x: 0, y: 0 };

        savingData.set(true)
    });

    $: {
        if (
            $currentProjectData &&
            $currentProjectData.pages //&&
            // $currentProjectData.pages[$currentPageIndex]
        ) {
            $currentProjectData.pages[$currentPageIndex] = {
                wallLocations: allWallCoordinates,
                antennaLocations: allAntennaCoordinates,
                calibratedWallPoints: scaleLineCoordinates,
                calWallPointsScale: scaleLength,
                imageScaleFactor: imageScale,
                url:
                    $currentProjectData.pages[$currentPageIndex] &&
                    $currentProjectData.pages[$currentPageIndex].url
                        ? $currentProjectData.pages[$currentPageIndex].url
                        : "",
                heatmapUrl: currentHeatmapUrl ? currentHeatmapUrl : "",
                cableLocations: allCoaxCoordinates,
                bdaLocations: allBDACoordinates,
                splitterLocations: allSplitterCoordinates,
                riserLocation: riserLocationCoordinates
            };
        }
        $currentProjectData.txFrequencyList = frequenciesList;
        $currentProjectData.txOutputPowerList = outputPowerList;

        savingData.set(true)
    }

    savingData.subscribe((value) => {
        if (value) {
            const projectRef =
                "users/" + $userId + "/projects" + `/${$currentProjectId}`;
            update(ref(db, projectRef), $currentProjectData).then(() => {
                savingData.set(false);
            });
        }
    });

    // $: {
    //     scaleLineCoordinates = scaleLineCoordinates;
    //     allCoaxCoordinates = allCoaxCoordinates;
    //     scaleLength = scaleLength;
    //     scaleRatio = scaleLength / scaleLinePixelLength;

    //     if (scaleRatio) {
    //         totalCoaxLength = 0;
    //         allCoaxCoordinates.forEach((coax) => {
    //             const a = Math.abs(coax.x1 - coax.x2);
    //             const b = Math.abs(coax.y1 - coax.y2);

    //             let currentCoaxPixelLength = Math.floor(
    //                 Math.sqrt(a * a + b * b)
    //             );

    //             let currentCoaxLengthInMeters =
    //                 currentCoaxPixelLength * scaleRatio;

    //             totalCoaxLength += Math.floor(currentCoaxLengthInMeters);
    //         });
    //     }
    // }

    $: {
        for (let wall of allWallTypes) {
            if (wallType == "") wallType = allWallTypes[0].name;
            if (wallType == wall.name) {
                wallStrokeStyle = wall.color;
                wallAttenuation = wall.attenuation;
                wallWidth = wall.width;
                wallTypeId = wall.id;
            }
        }
    }

    // $: {
    //     let allXCoordinates = allWallCoordinates
    //         .map((wall) => [Math.round(wall.x1), Math.round(wall.x2)])
    //         .flat();
    //     let allYCoordinates = allWallCoordinates
    //         .map((wall) => [Math.round(wall.y1), Math.round(wall.y2)])
    //         .flat();

    //     imgLeft = Math.min(...allXCoordinates);
    //     imgRight = Math.max(...allXCoordinates);
    //     imgTop = Math.min(...allYCoordinates);
    //     imgBottom = Math.max(...allYCoordinates);

    //     imgWidth = Math.abs(imgLeft - imgRight);
    //     imgHeight = Math.abs(imgTop - imgBottom);
    // }

    $: {
        heatmap = new Image();
        heatmap.src = currentHeatmapUrl;
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    // DRAWING ITEMS TO CANVAS
    ////////////////////////////////////////////////////////////////////////////////////////

    const draw = async () => {
        if (canvas) {
            scaleRatio = scaleLength / scaleLinePixelLength;
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            newImage = formatFloorImage(urls[$currentPageIndex].url, 1500);
            ctx.translate(window.innerWidth / 2, window.innerHeight / 2);
            ctx.scale(cameraZoom, cameraZoom);

            drawFloorImageToContext(
                newImage,
                ctx,
                imageScale,
                cameraOffset,
                -window.innerWidth,
                -window.innerHeight
            );

            heatmap = new Image();
            heatmap.src = currentHeatmapUrl ? currentHeatmapUrl : "";
            heatmapToggle &&
                currentHeatmapUrl &&
                currentHeatmapUrl.length &&
                drawHeatmapToContext(
                    heatmap,
                    ctx,
                    imageScale,
                    imgLeft,
                    imgTop,
                    imgWidth,
                    imgHeight,
                    0.6
                );
            drawAll();
            requestAnimationFrame(draw);
        }
    };

    const drawAll = () => {
        drawCurrent();

        showWalls &&
            drawWallsToCanvasContext(ctx, allWallCoordinates, imageScale);

        // showAntennas &&
        //     drawAntennasToCanvasContext(ctx, allAntennaCoordinates, imageScale);

        showCables &&
            drawCablesToCanvasContext(ctx, allCoaxCoordinates, imageScale, scaleRatio);

        showAntennas &&
            drawAntennasToCanvasContext(ctx, allAntennaCoordinates, imageScale);

        drawBDAToCanvasContext(ctx, allBDACoordinates, imageScale);

        drawSplittersToCanvasContext(ctx, allSplitterCoordinates, imageScale);
        
        drawRiserToCanvasContext(ctx, riserLocationCoordinates, imageScale);

        if (scaleLineCoordinates.length) {
            drawCalibratedWallPointsToCanvasContext(
                ctx,
                scaleLineCoordinates,
                imageScale
            );
            scaleLinePixelLength = lineDistanceInPixels(
                scaleLineCoordinates[0]
            );
        } else return;
    };

    const drawCurrent = () => {
        if (isDrawStart) {
            ctx.beginPath();
            ctx.lineWidth = 5;
            if (
                axisLocked &&
                Math.abs(startPosition.x - lineCoordinates.x) <
                    Math.abs(startPosition.y - lineCoordinates.y)
            ) {
                ctx.moveTo(startPosition.x, startPosition.y);
                ctx.lineTo(startPosition.x, lineCoordinates.y);
            } else if (
                axisLocked &&
                Math.abs(startPosition.x - lineCoordinates.x) >
                    Math.abs(startPosition.y - lineCoordinates.y)
            ) {
                ctx.moveTo(startPosition.x, startPosition.y);
                ctx.lineTo(lineCoordinates.x, startPosition.y);
            } else {
                ctx.moveTo(startPosition.x, startPosition.y);
                ctx.lineTo(lineCoordinates.x, lineCoordinates.y);
            }
            switch (currentTool) {
                case "WALL":
                    ctx.strokeStyle = wallStrokeStyle;
                    break;
                case "CABLE":
                    ctx.strokeStyle = "#B15252";
                    break;
                case "SCALE":
                    ctx.strokeStyle = "purple";
                    break;
            }
            ctx.stroke();
        }
    };

    const drawCalibratedWallPointsToCanvasContext = (
        ctx,
        wallPoints,
        scalingRatio
    ) => {
        const coordinates = wallPoints[0];
        ctx.beginPath();
        ctx.moveTo(
            coordinates.x1 * scalingRatio,
            coordinates.y1 * scalingRatio
        );
        ctx.lineTo(
            coordinates.x2 * scalingRatio,
            coordinates.y2 * scalingRatio
        );
        ctx.lineWidth = scalingRatio > 1 ? 15 : 4 / scalingRatio;
        ctx.strokeStyle = "purple";
        ctx.font = `${scalingRatio > 1 ? 60 : 16 / scalingRatio}px Arial`;
        ctx.fillText(
            scaleLength + "m",
            (coordinates.x1 * scalingRatio + coordinates.x2 * scalingRatio) /
                2,
            (coordinates.y1 * scalingRatio + coordinates.y2 * scalingRatio) /
                2
        );
        ctx.stroke();
    };

    ////////////////////////////////////////////////////////////////////////////////////////
    // MOUSE EVENT LISTENERS
    ////////////////////////////////////////////////////////////////////////////////////////

    const mouseDownListener = (event) => {
        if (event.button == 1) {
            return;
        }

        let mousePosition = getMousePosition(event);
        let x1, x2, y1, y2, sx, sy;

        if (axisLocked) {
            if (
                Math.abs(startPosition.x - lineCoordinates.x) <
                Math.abs(startPosition.y - lineCoordinates.y)
            ) {
                x1 = startPosition.x / imageScale;
                x2 = startPosition.x / imageScale;
                y1 = startPosition.y / imageScale;
                y2 = lineCoordinates.y / imageScale;

                sx = startPosition.x;
                sy = lineCoordinates.y;
            } else if (
                Math.abs(startPosition.x - lineCoordinates.x) >
                Math.abs(startPosition.y - lineCoordinates.y)
            ) {
                x1 = startPosition.x / imageScale;
                x2 = lineCoordinates.x / imageScale;
                y1 = startPosition.y / imageScale;
                y2 = startPosition.y / imageScale;

                sx = lineCoordinates.x;
                sy = startPosition.y;
            }
        } else {
            x1 = startPosition.x / imageScale;
            x2 = lineCoordinates.x / imageScale;
            y1 = startPosition.y / imageScale;
            y2 = lineCoordinates.y / imageScale;

            sx = getMousePosition(event).x;
            sy = getMousePosition(event).y;
        }

        switch (currentTool) {
            case "ANTENNA":
                allAntennaCoordinates.push({
                    _id: Math.abs(Math.floor(Math.random() * mousePosition.x)),
                    x: Math.round(mousePosition.x / imageScale),
                    y: Math.round(mousePosition.y / imageScale),
                });
                allAntennaCoordinates = allAntennaCoordinates;
                break;
            /////////////////////////////////////////////////////////////////////////////
            case "RISER":
                if (riserLocationCoordinates.length > 0) riserLocationCoordinates = [];
                    riserLocationCoordinates.push({
                        _id: Math.abs(Math.floor(Math.random() * mousePosition.x)),
                        x: Math.round(mousePosition.x / imageScale),
                        y: Math.round(mousePosition.y / imageScale),
                    });
                    riserLocationCoordinates = riserLocationCoordinates;
                break;

            case "BDA":
                allBDACoordinates.push({
                    _id: Math.abs(Math.floor(Math.random() * mousePosition.x)),
                    x: Math.round(mousePosition.x / imageScale),
                    y: Math.round(mousePosition.y / imageScale),
                });
                allBDACoordinates = allBDACoordinates;
                break;

            case "SPLITTER":
                allSplitterCoordinates.push({
                    _id: Math.abs(Math.floor(Math.random() * mousePosition.x)),
                    x: Math.round(mousePosition.x / imageScale),
                    y: Math.round(mousePosition.y / imageScale),
                });
                allSplitterCoordinates = allSplitterCoordinates;
                break;

            /////////////////////////////////////////////////////////////////////////////

            case "WALL":
                if (isDrawStart) {
                    allWallCoordinates.push({
                        _id: Math.abs(
                            Math.floor(Math.random() * startPosition.x)
                        ),
                        x1: x1,
                        x2: x2,
                        y1: y1,
                        y2: y2,
                        attenuation: wallAttenuation,
                        strokeStyle: wallStrokeStyle,
                        width: wallWidth,
                        type_id: wallTypeId,
                    });
                    startPosition = {
                        x: sx,
                        y: sy,
                    };
                } else {
                    isDrawStart = true;
                    startPosition = getMousePosition(event);
                }
                allWallCoordinates = allWallCoordinates;
                break;
            case "CABLE":
                if (isDrawStart) {
                    allCoaxCoordinates.push({
                        _id: Math.abs(
                            Math.floor(Math.random() * startPosition.x)
                        ),
                        x1: x1,
                        x2: x2,
                        y1: y1,
                        y2: y2,
                        attenuation: wallAttenuation,
                        strokeStyle: wallStrokeStyle,
                        width: wallWidth,
                        type_id: wallTypeId,
                    });
                    startPosition = {
                        x: sx,
                        y: sy,
                    };
                } else {
                    isDrawStart = true;
                    startPosition = getMousePosition(event);
                }
                allCoaxCoordinates = allCoaxCoordinates;
                break;
            case "ERASER":
                let wallIdToDelete = "";
                allWallCoordinates.forEach((g) => {
                    let wallPath = new Path2D();
                    wallPath.moveTo(g.x1 * imageScale, g.y1 * imageScale);
                    wallPath.lineTo(g.x2 * imageScale, g.y2 * imageScale);
                    ctx.stroke(wallPath);
                    if (
                        ctx.isPointInStroke(
                            wallPath,
                            event.offsetX,
                            event.offsetY
                        )
                    ) {
                        wallIdToDelete = g._id;
                    }
                });
                allWallCoordinates = allWallCoordinates.filter(
                    (g) => g._id !== wallIdToDelete
                );

                let antennaIdToDelete = "";
                allAntennaCoordinates.forEach((g) => {
                    let antPath = new Path2D();
                    antPath.arc(
                        g.x * imageScale,
                        g.y * imageScale,
                        imageScale > 1 ? 10 : 4 / imageScale,
                        0,
                        2 * Math.PI
                    );
                    ctx.stroke(antPath);
                    if (
                        ctx.isPointInStroke(
                            antPath,
                            event.offsetX,
                            event.offsetY
                        )
                    ) {
                        antennaIdToDelete = g._id;
                    }
                });
                allAntennaCoordinates = allAntennaCoordinates.filter(
                    (g) => g._id !== antennaIdToDelete
                );

                ////////////////////////////////////////////////////////////////
                let bdaIdToDelete = "";
                allBDACoordinates.forEach((bda) => {
                    let bdaPath = new Path2D();
                    bdaPath.arc(
                        bda.x * imageScale,
                        bda.y * imageScale,
                        imageScale > 1 ? 10 : 4 / imageScale,
                        0,
                        2 * Math.PI
                    );
                    ctx.stroke(bdaPath);
                    if (
                        ctx.isPointInStroke(
                            bdaPath,
                            event.offsetX,
                            event.offsetY
                        )
                    ) {
                        bdaIdToDelete = bda._id;
                    }
                });
                allBDACoordinates = allBDACoordinates.filter(
                    (bda) => bda._id !== bdaIdToDelete
                );

                riserLocationCoordinates.forEach((riser) => {
                    let riserPath = new Path2D();
                    riserPath.arc(
                        riser.x * imageScale,
                        riser.y * imageScale,
                        imageScale > 1 ? 10 : 4 / imageScale,
                        0,
                        2 * Math.PI
                    );
                    ctx.stroke(riserPath);
                    if (
                        ctx.isPointInStroke(
                            riserPath,
                            event.offsetX,
                            event.offsetY
                        )
                    ) {
                        riserLocationCoordinates = [];
                    }
                });

                let splitterIdToDelete = "";
                allSplitterCoordinates.forEach((splitter) => {
                    let splitterPath = new Path2D();
                    splitterPath.arc(
                        splitter.x * imageScale,
                        splitter.y * imageScale,
                        imageScale > 1 ? 10 : 4 / imageScale,
                        0,
                        2 * Math.PI
                    );
                    ctx.stroke(splitterPath);
                    if (
                        ctx.isPointInStroke(
                            splitterPath,
                            event.offsetX,
                            event.offsetY
                        )
                    ) {
                        splitterIdToDelete = splitter._id;
                    }
                });
                allSplitterCoordinates = allSplitterCoordinates.filter(
                    (splitter) => splitter._id !== splitterIdToDelete
                );
                ////////////////////////////////////////////////////////////////

                let coaxIdToDelete = "";
                allCoaxCoordinates.forEach((g) => {
                    let coaxPath = new Path2D();
                    coaxPath.moveTo(g.x1 * imageScale, g.y1 * imageScale);
                    coaxPath.lineTo(g.x2 * imageScale, g.y2 * imageScale);
                    ctx.stroke(coaxPath);
                    if (
                        ctx.isPointInStroke(
                            coaxPath,
                            event.offsetX,
                            event.offsetY
                        )
                    ) {
                        coaxIdToDelete = g._id;
                    }
                });
                allCoaxCoordinates = allCoaxCoordinates.filter(
                    (g) => g._id !== coaxIdToDelete
                );
                break;

            case "SCALE":
                if (scaleLineCoordinates.length > 0) scaleLineCoordinates = [];
                if (isDrawStart) {
                    scaleLineCoordinates.push({
                        x1: x1,
                        x2: x2,
                        y1: y1,
                        y2: y2,
                    });
                    scaleLineCoordinates = scaleLineCoordinates;
                    isDrawStart = false;
                    isSettingScale = true;
                } else {
                    isDrawStart = true;
                }
                startPosition = getMousePosition(event);
                break;
        }
    };

    $: totalCoaxLength = calculateTotalCoaxLength(
        allCoaxCoordinates,
        scaleRatio
    );

    const mouseMoveListener = (event) => {
        if (!isDrawStart && currentTool === "ERASER") {
            allWallCoordinates.forEach((wall) => {
                let wallPath = new Path2D();
                wallPath.moveTo(wall.x1 * imageScale, wall.y1 * imageScale);
                wallPath.lineTo(wall.x2 * imageScale, wall.y2 * imageScale);
                ctx.stroke(wallPath);
                if (
                    ctx.isPointInStroke(wallPath, event.offsetX, event.offsetY)
                ) {
                    wall.isHover = true;
                } else wall.isHover = false;
            });
            allAntennaCoordinates.forEach((ant) => {
                let antPath = new Path2D();
                antPath.rect(
                    ant.x * imageScale,
                    ant.y * imageScale,
                    20 * imageScale,
                    20 * imageScale
                );
                ctx.stroke(antPath);
                if (
                    ctx.isPointInStroke(antPath, event.offsetX, event.offsetY)
                ) {
                    ant.isHover = true;
                } else ant.isHover = false;
            });
            // allBDACoordinates.forEach((bda) => {
            //     let bdaPath = new Path2D();
            //     bdaPath.rect(
            //         bda.x * imageScale,
            //         bda.y * imageScale,
            //         20 * imageScale,
            //         20 * imageScale
            //     );
            //     ctx.stroke(bdaPath);
            //     // if (
            //     //     ctx.isPointInStroke(bdaPath, event.offsetX, event.offsetY)
            //     // ) {
            //     //     ant.isHover = true;
            //     // } else ant.isHover = false;
            // });
            allCoaxCoordinates.forEach((coax) => {
                let coaxPath = new Path2D();
                coaxPath.moveTo(coax.x1 * imageScale, coax.y1 * imageScale);
                coaxPath.lineTo(coax.x2 * imageScale, coax.y2 * imageScale);
                ctx.stroke(coaxPath);
                if (
                    ctx.isPointInStroke(coaxPath, event.offsetX, event.offsetY)
                ) {
                    coax.isHover = true;
                } else coax.isHover = false;
            });
            return;
        }

        lineCoordinates = getMousePosition(event);
        clearCanvas();
        drawCurrent();
    };

    const onPointerDown = (e) => {
        isDragging = true;
        dragStart.x = getEventLocation(e).x / cameraZoom - cameraOffset.x;
        dragStart.y = getEventLocation(e).y / cameraZoom - cameraOffset.y;

        getMousePosition(e);
    };

    const onPointerUp = (e) => {
        isDragging = false;
        lastZoom = cameraZoom;
    };

    const onPointerMove = (e) => {
        if (isDragging) {
            cameraOffset.x = getEventLocation(e).x / cameraZoom - dragStart.x;
            cameraOffset.y = getEventLocation(e).y / cameraZoom - dragStart.y;
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////
    // CONTROL HELPER FUNCTIONS
    ////////////////////////////////////////////////////////////////////////////////////////

    const clearCanvas = () =>
        ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);

    const handleKeyDown = (e) => {
        switch (e.key) {
            case "Escape":
                isDrawStart = false;
                break;
            case "Shift":
                axisLocked = axisLocked ? false : true;
                break;
        }
    };

    const getMousePosition = (event) => {
        const xCanvasOffset = window.innerWidth / 2;
        const yCanvasOffset = window.innerHeight / 2;

        const xCanvasLocation = getEventLocation(event).x;
        const yCanvasLocation = getEventLocation(event).y;

        const xPanOffset = cameraOffset.x - xCanvasOffset;
        const yPanOffset = cameraOffset.y - yCanvasOffset;

        const xLocation =
            (xCanvasLocation - xCanvasOffset) / cameraZoom - xPanOffset;
        const yLocation =
            (yCanvasLocation - yCanvasOffset) / cameraZoom - yPanOffset;

        return { x: xLocation, y: yLocation };
    };

    let lastZoom = cameraZoom;

    const adjustZoom = (zoomAmount, zoomFactor) => {
        if (!isDragging) {
            if (zoomAmount) {
                cameraZoom += zoomAmount;
            } else if (zoomFactor) {
                cameraZoom = zoomFactor * lastZoom;
            }

            cameraZoom = Math.min(cameraZoom, MAX_ZOOM);
            cameraZoom = Math.max(cameraZoom, MIN_ZOOM);
        }
    };

    const handleWheelButton = (e, wheelButtonHandler) =>
        e.button == 1 && wheelButtonHandler(e);

    const deleteFreqHandler = (freq) =>
        (frequenciesList = frequenciesList.filter((x) => x.freq != freq));

    const deleteOutputPowerHandler = (power) =>
        (outputPowerList = outputPowerList.filter((x) => x.power != power));

    ////////////////////////////////////////////////////////////////////////////////////////
    // EXPORTING
    ////////////////////////////////////////////////////////////////////////////////////////

    const exportAsPng = async (currentPage, newImage, heatmapImage) => {
        const tempCvs = document.createElement("canvas");
        const tempCtx = tempCvs.getContext("2d");

        if (newImage === "") {
            newImage = new Image();
            newImage.crossOrigin = "anonymous";
            newImage.src = urls[Number(currentPage)].url;
        }

        const { img, isSmall } = formatExportImage(newImage);

        newImage = img;

        let exportScaleRatio = isSmall
            ? 1
            : newImage.width > newImage.height
            ? newImage.width / 1500
            : newImage.height / 1500;

        tempCvs.width = newImage.width;
        tempCvs.height = newImage.height;

        drawFloorImageToContext(
            newImage,
            tempCtx,
            1,
            { x: 0, y: 0 },
            tempCvs.width,
            tempCvs.height
        );

        const {
            wallLocations,
            antennaLocations,
            calibratedWallPoints,
            cableLocations,
        } = $currentProjectData.pages[currentPage];

        const { left, top, width, height } =
            getHeatmapDimensions(wallLocations);

        //If heatmap exists, scale heatmap
        heatmapImage &&
            drawHeatmapToContext(
                heatmapImage,
                tempCtx,
                exportScaleRatio,
                left,
                top,
                width,
                height,
                0.6
            );

        //Draw and scale all current walls
        showWalls &&
            wallLocations &&
            drawWallsToCanvasContext(tempCtx, wallLocations, exportScaleRatio);

                //Draw and scale all current cable
        showCables &&
            cableLocations &&
            drawCablesToCanvasContext(
                tempCtx,
                cableLocations,
                exportScaleRatio,
                scaleRatio
            );

        //Draw and scale all current antennas
        showAntennas &&
            antennaLocations &&
            drawAntennasToCanvasContext(
                tempCtx,
                antennaLocations,
                exportScaleRatio
            );

        //Draw and scale scale reference line
        calibratedWallPoints &&
            calibratedWallPoints.length &&
            drawCalibratedWallPointsToCanvasContext(
                tempCtx,
                calibratedWallPoints,
                exportScaleRatio
            );

        drawBDAToCanvasContext(tempCtx, allBDACoordinates, exportScaleRatio);

        drawSplittersToCanvasContext(tempCtx, allSplitterCoordinates, exportScaleRatio);

        drawRiserToCanvasContext(tempCtx, riserLocationCoordinates, exportScaleRatio);

        downloadCanvasAsPNG(tempCvs, currentPage);
    };
    
    const exportCurrentPage = async (currentPage) => {
        const pageUrls = urls[currentPage];
        const floorImage = await loadImage({
            idx: pageUrls.idx,
            url: pageUrls.url,
        });
        let heatmapImage = {};
        heatmapImage.img = "";
        if (heatmapToggle && pageUrls.hmUrl) {
            heatmapImage = await loadImage({
                idx: pageUrls.idx,
                url: pageUrls.hmUrl,
            });
        }
        exportAsPng(currentPage, floorImage.img, heatmapImage.img);
    };

    const exportAll = async () => {
        const floorImageUrls = [];
        const heatmapImageUrls = [];
        for (let item of urls) {
            floorImageUrls.push({ url: item.url, idx: item.idx });
            if (item.hmUrl) {
                heatmapImageUrls.push({ url: item.hmUrl, idx: item.idx });
            }
        }
        const loadedImages = await Promise.all([
            Promise.all(floorImageUrls.map(loadImage)),
            Promise.all(heatmapImageUrls.map(loadImage)),
        ]);

        const images = [];

        for (let floorImage of loadedImages[0]) {
            let hasHm = false;
            for (let heatmapImage of loadedImages[1]) {
                if (floorImage.idx == heatmapImage.idx) {
                    hasHm = true;
                    images.push({
                        idx: floorImage.idx,
                        img: floorImage.img,
                        hmImg: heatmapImage.img,
                    });
                }
            }
            if (!hasHm)
                images.push({ idx: floorImage.idx, img: floorImage.img });
        }

        for (let floor of images) {
            exportAsPng(floor.idx, floor.img, floor.hmImg);
        }
    };
</script>

<main>
    <div id="container">
        <Overlay
            bind:currentTool
            bind:allWallCoordinates
            bind:allAntennaCoordinates
            bind:allCoaxCoordinates
            bind:heatmap
            bind:imgLeft
            bind:imgRight
            bind:imgTop
            bind:imgBottom
            bind:imgWidth
            bind:imgHeight
            bind:scaleLength
            bind:scaleLinePixelLength
            bind:totalCoaxLength
            bind:scaleRatio
            bind:scaleLineCoordinates
            bind:wallType
            bind:imageScale
            bind:showAntennaIds
            bind:showWallIds
            bind:showAntennas
            bind:showWalls
            bind:showCables
            bind:frequenciesList
            bind:pageMetadata
            bind:heatmapToggle
            bind:currentHeatmapUrl
            {connectedToServer}
            {allWallTypes}
            {deleteOutputPowerHandler}
            {deleteFreqHandler}
            {outputPowerList}
            {totalNumPages}
            {exportCurrentPage}
            {exportAll}
            {socket}
        />
        <canvas
            id="canvas"
            bind:this={canvas}
            on:mouseup={onPointerUp}
            on:mousemove={onPointerMove}
            on:mousemove={mouseMoveListener}
            on:mousedown={mouseDownListener}
            on:mousedown={(e) => handleWheelButton(e, onPointerDown)}
            on:wheel|passive={(e) => adjustZoom(e.deltaY * SCROLL_SENSITIVITY)}
        />
    </div>
</main>

<svelte:window on:keydown={handleKeyDown} />

<style>
    main {
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        height: 100%;
        background: #dbdbdb;
    }
</style>

<script>
    export let currentToolTab;
</script>

<main>
    <div id="tool-tabs">
        <div
            class="tool-tabs-item"
            style={currentToolTab === "wall" && "color: white;"}
            on:click={() => (currentToolTab = "wall")}
        >
            Wall
        </div>
        <div
            class="tool-tabs-item"
            style={currentToolTab === "antenna" && "color: white;"}
            on:click={() => (currentToolTab = "antenna")}
        >
            Antenna
        </div>
        <div
            class="tool-tabs-item"
            style={currentToolTab === "cable" && "color: white;"}
            on:click={() => (currentToolTab = "cable")}
        >
            Cable
        </div>
        <!-- <div
            class="tool-tabs-item"
            style={currentToolTab === "trash" && "color: white;"}
            on:click={() => (currentToolTab = "trash")}
        >
            Trash
        </div> -->
        <div
            class="tool-tabs-item"
            style={currentToolTab === "scale" && "color: white;"}
            on:click={() => (currentToolTab = "scale")}
        >
            Scale
        </div>
        <div
            class="tool-tabs-item"
            style={currentToolTab === "propagate" && "color: white;"}
            on:click={() => (currentToolTab = "propagate")}
        >
            Heatmap
        </div>
        <div
            class="tool-tabs-item"
            style={currentToolTab === "freqs" && "color: white;"}
            on:click={() => (currentToolTab = "freqs")}
        >
            Freqs
        </div>
    </div>
</main>

<style>
    #tool-tabs {
        display: flex;
        width: 14vw;
        min-width: 8rem;
        flex-wrap: wrap-reverse;
        color: black;
        border-bottom: 1px solid gray;
        justify-content: center;
    }

    .tool-tabs-item {
        background-color: gray;
        cursor: pointer;
        text-align: center;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-top: 1px solid black;
        padding-left: 0.25rem;
        /* padding-left: 0.1rem; */
        padding-right: 0.25rem;
        font-size: 0.9rem;
        border-radius: 4px 5px 0px 0px;
        transition: 0.2s;
    }

    .tool-tabs-item:hover {
        color: white;
        transition: 0.2s;
    }
</style>

<script>
    import LoginScreen from "./Pages/UserAuth/LoginScreen.svelte";
    import MainCanvas from "./Pages/DesignEnvironment/Canvas/MainCanvas.svelte";
    import MainMenu from "./Pages/MainMenu/MainMenu.svelte";
    import { isLoggedIn, projectIsOpen, userId } from "./stores";
    import { getAuth } from "firebase/auth";
    import { getDatabase } from "firebase/database";
    import { getStorage } from "firebase/storage";
    import { firebaseApp } from "./auth/index";
    import { io } from "socket.io-client";

    // Initialize Firebase Authentication and get a reference to the service
    const auth = getAuth(firebaseApp);
    getDatabase(firebaseApp);
    getStorage(firebaseApp);

    isLoggedIn.subscribe(
        (value) => ($isLoggedIn = JSON.parse(value) === true ? true : false)
    );

    projectIsOpen.subscribe(
        (value) => ($projectIsOpen = JSON.parse(value) === true ? true : false)
    );

    userId.subscribe((value) => ($userId = value ? value : null));

    auth.onAuthStateChanged((user) => {
        if (user != null) {
            isLoggedIn.set(true);
            userId.set(user.uid);
        } else isLoggedIn.set(false);
    });

    const socket = io("wss://staging.drf-papi.com/");
    // const socket = io("ws://127.0.0.1:8080")

    let connectedToServer = false;

    // client-side
    socket.on("connect", () => {
        connectedToServer = true;
    });

    socket.on("disconnect", () => {
        connectedToServer = false;
    });
</script>

<!-- GOOD VERSION? -->
<main>
    {#if !$isLoggedIn}
        <LoginScreen />``
    {/if}

    {#if $isLoggedIn && !$projectIsOpen}
        <MainMenu />
    {/if}

    {#if $isLoggedIn && $projectIsOpen}
        <MainCanvas {socket} {connectedToServer} />
    {/if}
</main>

<style>
    main {
        height: 100%;
        width: 100%;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
</style>

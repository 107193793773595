<script>
    import DownArrow from "../../../../../public/svg/down-arrow-svgrepo-com.svelte";
    import UpArrow from "../../../../../public/svg/up-arrow-svgrepo-com(1).svelte";
    import DeleteButton from "../../../../../public/svg/delete-svgrepo-com.svelte";
    import EditSvg from "../../../../../public/svg/editSVG.svelte";
    export let file;
    export let fileUrl;
    export let fileControlHandler;

    let fileName = file.name;
    let isChangingFileName = false;
</script>

<main>
    <div id="file-item-wrapper">
        <div id="file-image-wrapper">
            <div id="file-item-floor-number">
                {Number(file.idx) + 1}
            </div>
            <img id="file-preview" src={fileUrl} alt="page" />
        </div>
        {#if isChangingFileName}
            <form
                id="file-item-title"
                on:submit|preventDefault={() => {
                    file.name = fileName;
                    file = file;
                    isChangingFileName = false;
                }}
            >
                <input
                    placeholder={file.name}
                    bind:value={fileName}
                    class="form-input"
                />
                <button type="submit" class="submit-button">☑</button>
            </form>
        {:else}
            <div id="file-item-title">
                <div>
                    {file.name}
                </div>
                <div
                    on:click={() => (isChangingFileName = true)}
                    class="edit-file-item"
                >
                    <EditSvg />
                </div>
            </div>
        {/if}
        <!-- {file.item.name} -->
        <!-- </input> -->
        <div id="control-wrapper">
            <div>
                <div
                    on:click={!isChangingFileName &&
                        fileControlHandler("up", file.idx)}
                >
                    <UpArrow />
                </div>
                <div
                    on:click={!isChangingFileName &&
                        fileControlHandler("down", file.idx)}
                >
                    <DownArrow />
                </div>
            </div>
            <div
                id="delete-button"
                on:click={!isChangingFileName &&
                    fileControlHandler("delete", file.idx)}
            >
                <DeleteButton />
            </div>
        </div>
    </div>
</main>

<style>
    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
    #file-item-wrapper {
        position: relative;
        margin: 1rem;
        /* width: 100%; */
        height: 4rem;
        background-color: gray;
        border-radius: 10px;
        display: flex;
        align-items: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }

    #file-item-title {
        margin-left: 1rem;
        width: 40%;
        display: flex;
    }

    #file-item-floor-number {
        position: absolute;
        left: 0;
        top: 0;
        font-weight: bold;
        text-align: center;
        color: #00d1ff;
        margin-left: 0.25rem;
    }

    #file-preview {
        max-width: 2rem;
        max-height: 2rem;
    }

    #file-image-wrapper {
        width: 4rem;
        height: 3.5rem;
        background-color: whitesmoke;
        border-radius: 10px;
        margin-left: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        position: relative;
    }

    #control-wrapper {
        position: absolute;
        right: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.25rem;
    }

    #delete-button {
        margin-left: 0.5rem;
    }

    .edit-file-item {
        top: 1.15rem;
        cursor: pointer;
        position: absolute;
        right: 4.75rem;
    }

    .submit-button {
        font-size: 1.75rem;
        top: 0.75rem;
        cursor: pointer;
        position: absolute;
        right: 4.85rem;
        transition: 0.2s;
    }

    .submit-button:hover {
        transition: 0.2s;
        color: green;
    }

    input {
        border: none;
        background-image: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 20rem;
        height: 2.5rem;
        border-radius: 10px;
        background-color: white;
        font-weight: bold;
        padding-left: 0.5rem;
    }
</style>

<script>
    export let imageUploadCount;
    export let totalImages;
</script>

<main>
    <div id="project-creation-loading">
        <div id="inner-pcl">
            <div>Project is being created</div>
        </div>
        <div id="loading-bar">
            <div
                id="the-actual-bar"
                style={`width: ${Math.floor(
                    (100 / totalImages) * imageUploadCount
                )}%;`}
            >
                <img
                    src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                    alt="loading..."
                />
                <div id="percentage">
                    {Math.floor((100 / totalImages) * imageUploadCount)}%
                </div>
            </div>
        </div>
    </div>
</main>

<style>
    #project-creation-loading {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 10000;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 50%);
        color: white;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
    #inner-pcl {
        display: flex;
        width: 12.5rem;
        justify-content: space-between;
        align-items: center;
    }

    #loading-bar img {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.5rem;
    }

    #loading-bar {
        margin-top: 1rem;
        width: 15rem;
        height: 2.1rem;
        background-color: rgba(0, 0, 0, 25%);
        border: 1px solid black;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }

    #the-actual-bar {
        background-color: #00d1ff;
        height: 2rem;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }

    #percentage {
        margin-left: 0.5rem;
        font-weight: bold;
    }
</style>

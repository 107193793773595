<script>
    import ProjectLabel from "./OverlayItems/ProjectLabel.svelte";
    import ToolBar from "./OverlayItems/ToolBar/ToolBar.svelte";
    import StatusBox from "./OverlayItems/StatusBox.svelte";
    import FloorPlanBar from "./OverlayItems/FloorPlanBar.svelte";
    import ProfileBar from "./OverlayItems/ProfileBar.svelte";
    import ToolSettingsBox from "./OverlayItems/ToolSettingsBox/ToolSettingsBox.svelte";
    import HeatMapSettingsBox from "./OverlayItems/HeatMapSettingsBox.svelte";
    import PropagationErrors from "./PropagationErrors.svelte";
    import ProjectItemsListBox from "./OverlayItems/ProjectItemsListBox.svelte";
    import SocketStatusBox from "./OverlayItems/SocketStatusBox.svelte";
    import ToolBox from "./OverlayItems/ToolBox.svelte";
    import {
        userId,
        currentPageIndex,
        currentProjectId,
        currentProjectData,
        savingData,
    } from "../../../stores";
    import {
        getStorage,
        ref as storgRef,
        uploadBytes,
        getDownloadURL,
    } from "firebase/storage";
    import { update, set, ref, getDatabase } from "firebase/database";

    export let currentTool;
    export let allWallCoordinates;
    export let allAntennaCoordinates;
    export let allCoaxCoordinates;
    export let heatmap;
    export let imgLeft;
    export let imgRight;
    export let imgTop;
    export let imgBottom;
    export let imgHeight;
    export let imgWidth;
    export let scaleLength;
    export let scaleLinePixelLength;
    export let totalCoaxLength;
    export let scaleRatio;
    export let scaleLineCoordinates;
    export let wallType;
    export let frequenciesList;
    export let outputPowerList;
    export let imageScale;
    export let totalNumPages;
    export let showAntennaIds;
    export let showWallIds;
    export let showAntennas;
    export let showWalls;
    export let showCables;
    export let deleteFreqHandler;
    export let deleteOutputPowerHandler;
    export let pageMetadata;
    export let allWallTypes;
    export let exportCurrentPage;
    export let exportAll;
    export let socket;
    export let connectedToServer;
    export let heatmapToggle;
    export let currentHeatmapUrl;

    const storage = getStorage();
    const db = getDatabase();

    let currentOutputPower = 0;
    let finalAntennaCoordinates = [];
    let finalWallCoordinates = [];
    let isPropagating = false;
    let currentFrequency = 0;
    let errorMessageArray = [];
    let errors = false;
    let currentNodeAmount = 0;
    let currentResolution = "";
    let scaleDivisor = 1;
    let tempTool = "";
    let propagationStatus = "";

    $: {
        switch (currentResolution) {
            case "25%":
                scaleDivisor = 4;
                break;
            case "50%":
                scaleDivisor = 2;
                break;
            case "75%":
                scaleDivisor = 1.33;
                break;
            case "FULL":
                scaleDivisor = 1;
                break;
        }
    }

    $: {
        if (errors) {
            setTimeout(() => {
                errors = false;
                errorMessageArray = [];
            }, 4000);
        }
    }

    $: isPropagating = !connectedToServer && false;

    let errorMessages = {
        wall: {
            noWalls: "ERROR: No walls drawn",
        },
        antennas: {
            noAntennas: "ERROR: No antennas placed",
        },
        freqs: {
            noFreq: "ERROR: No frequency chosen",
        },
        scale: {
            noValue: "ERROR: No scale value set",
            noRefLine: "ERROR: No scale reference line drawn",
        },
        power: {
            noPower: "ERROR: No antenna output power chosen",
        },
        node: {
            noNode: "ERROR: No nodes per meter chosen",
        },
        res: {
            noRes: "ERROR: No resolution scale chosen",
        },
    };

    const clearButtonHandler = () => {
        allAntennaCoordinates = [];
        allWallCoordinates = [];
        finalAntennaCoordinates = [];
        finalWallCoordinates = [];
        allCoaxCoordinates = [];
        heatmap = new Image();
        totalCoaxLength = 0;
    };

    const propagateButtonHandler = () => {
        socket.emit("message", "what's up brah?");

        tempTool = currentTool;
        currentTool = "";

        if (allWallCoordinates.length < 1 || !allWallCoordinates) {
            errorMessageArray.push(errorMessages.wall.noWalls);
        }
        if (allAntennaCoordinates.length < 1 || !allAntennaCoordinates) {
            errorMessageArray.push(errorMessages.antennas.noAntennas);
        }
        if (!currentFrequency) {
            errorMessageArray.push(errorMessages.freqs.noFreq);
        }
        if (scaleLineCoordinates.length < 1 || !scaleLineCoordinates) {
            errorMessageArray.push(errorMessages.scale.noRefLine);
        }
        if (scaleLength == 0) {
            errorMessageArray.push(errorMessages.scale.noValue);
        }
        if (!currentOutputPower) {
            errorMessageArray.push(errorMessages.power.noPower);
        }
        if (currentNodeAmount == 0) {
            errorMessageArray.push(errorMessages.node.noNode);
        }
        if (currentResolution == "") {
            errorMessageArray.push(errorMessages.res.noRes);
        }

        if (errorMessageArray.length) {
            errors = true;
            return;
        }

        allAntennaCoordinates.forEach((antenna) => {
            finalAntennaCoordinates.push([
                Math.round(antenna.x / scaleDivisor),
                Math.round(antenna.y / scaleDivisor),
            ]);
        });

        allWallCoordinates.forEach((wall) => {
            finalWallCoordinates.push(
                Math.round(wall.x1 / scaleDivisor) +
                    "," +
                    Math.round(wall.y1 / scaleDivisor) +
                    "," +
                    Math.round(wall.x2 / scaleDivisor) +
                    "," +
                    Math.round(wall.y2 / scaleDivisor) +
                    "," +
                    wall.attenuation
            );
        });

        let finalScaleCoordinates = [
            [
                Math.round(scaleLineCoordinates[0].x1 / scaleDivisor),
                Math.round(scaleLineCoordinates[0].y1 / scaleDivisor),
            ],
            [
                Math.round(scaleLineCoordinates[0].x2 / scaleDivisor),
                Math.round(scaleLineCoordinates[0].y2 / scaleDivisor),
            ],
        ];

        isPropagating = true;

        socket.emit("generate-heatmap", {
            wallLocations: finalWallCoordinates.slice(
                0,
                finalWallCoordinates.length
            ),
            antLocations: finalAntennaCoordinates,
            wallLenMet: scaleLength,
            calibWallPoints: finalScaleCoordinates,
            txFrequency: currentFrequency * 1000000,
            txOutputPower: currentOutputPower,
            nodesPerMeter: currentNodeAmount,
            projectId: $currentProjectId,
        });
        finalAntennaCoordinates = [];
        finalWallCoordinates = [];
    };

    socket.on("update", (arg) => {
        propagationStatus = arg;
    });

    socket.on("finished-heatmap", async (arg) => {
        currentTool = tempTool;
        const result = new Blob([arg], { type: "image/png" });
        if (result.type !== "image/png") {
            errorMessageArray.push(
                "ERROR: Heatmap generation failed. Change parameters and try again."
            );
            errors = true;
            isPropagating = false;
            return;
        }
        const img = URL.createObjectURL(result);
        heatmap.setAttribute("src", img);
        isPropagating = false;

        let allXCoordinates = allWallCoordinates
            .map((wall) => [Math.round(wall.x1), Math.round(wall.x2)])
            .flat();
        let allYCoordinates = allWallCoordinates
            .map((wall) => [Math.round(wall.y1), Math.round(wall.y2)])
            .flat();

        imgLeft = Math.min(...allXCoordinates);
        imgRight = Math.max(...allXCoordinates);
        imgTop = Math.min(...allYCoordinates);
        imgBottom = Math.max(...allYCoordinates);

        imgWidth = Math.abs(imgLeft - imgRight);
        imgHeight = Math.abs(imgTop - imgBottom);

        const pageHeatmapRef = storgRef(
            storage,
            `users/${$userId}/projects/${$currentProjectId}/heatmaps/hm-${$currentPageIndex}.png`
        );

        const projectRef =
            "users/" + $userId + "/projects" + `/${$currentProjectId}`;

        const file = new File([arg], `hm-${$currentPageIndex}.png`, {
            type: "image/png",
            lastModified: new Date(),
        });

        await uploadBytes(pageHeatmapRef, file);
        const hmUrl = await getDownloadURL(pageHeatmapRef);
        const allPages = $currentProjectData.pages;
        allPages[$currentPageIndex].heatmapUrl = hmUrl;
        currentHeatmapUrl = hmUrl;

        update(ref(db, projectRef), { pages: allPages });

        savingData.set(true);

        window.location.reload();
    });
</script>

<main>
    <div id="overlay-wrapper">
            <ProjectLabel />

            <ToolBar
                bind:currentTool
                bind:wallType
                {propagateButtonHandler}
                {clearButtonHandler}
                {isPropagating}
                {allWallTypes}
                {exportCurrentPage}
                {exportAll}
            />
            <HeatMapSettingsBox />

            <!-- <ToolBox bind:currentTool/> -->

            <FloorPlanBar {totalNumPages} bind:pageMetadata {isPropagating} />

            {#if errors}
                <PropagationErrors errors={errorMessageArray} />
            {/if}

            <StatusBox
                {currentTool}
                {isPropagating}
                {wallType}
                {allWallTypes}
                {propagationStatus}
                {socket}
            />

            <SocketStatusBox {connectedToServer} />

            <ProfileBar />

            <ToolSettingsBox
                bind:scaleRatio
                bind:scaleLinePixelLength
                bind:scaleLength
                bind:imageScale
                bind:currentFrequency
                bind:wallType
                bind:currentOutputPower
                bind:frequenciesList
                bind:currentNodeAmount
                bind:currentResolution
                bind:currentTool
                bind:allWallCoordinates
                {deleteFreqHandler}
                {outputPowerList}
                {propagateButtonHandler}
                {deleteOutputPowerHandler}
                {allWallTypes}
                {isPropagating}
                {totalCoaxLength}
            />

            <ProjectItemsListBox
                bind:heatmap
                bind:showWallIds
                bind:showAntennaIds
                bind:showAntennas
                bind:showWalls
                bind:showCables
                bind:pageMetadata
                bind:heatmapToggle
            />
    </div>
</main>

<!-- 
// const pageHeatmapRef = storgRef(
    //     storage,
    //     `users/${$userId}/projects/${$currentProjectId}/heatmaps/hm-${$currentPageIndex}.png`
    // );

    // const projectRef =
    //     "users/" + $userId + "/projects" + `/${$currentProjectId}`;

    // const file = new File([blob], `hm-${$currentPageIndex}.png`, {
    //     type: "image/png",
    //     lastModified: new Date(),
    // });

    // await uploadBytes(pageHeatmapRef, file)

    // const hmUrl = await getDownloadURL(pageHeatmapRef);
    // const allPages = currProjData.pages;
    // allPages[$currentPageIndex].heatmapUrl = hmUrl;

    // update(ref(db, projectRef), { pages: allPages }) -->
<style>
    .overlay-side {
        display: flex;
        flex-direction: column;
    }
</style>

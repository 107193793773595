<script>
    import {
        currentProjectName,
        userId,
        currentProjectId,
    } from "../../../stores.js";
    import { ref, getDatabase, onValue, update } from "firebase/database";

    export let optionsModalOpen;

    let newProjectName = $currentProjectName;

    let frequenciesList = [];
    let outputPowerList = [];

    const db = getDatabase();
    const projectRef = ref(
        db,
        "users/" + $userId + "/projects" + `/${$currentProjectId}`
    );

    onValue(projectRef, (snapshot) => {
        const data = snapshot.val();
        if (data != null) {
            const { txFrequencyList, txOutputPowerList } = data;
            frequenciesList = txFrequencyList ? txFrequencyList : [];
            outputPowerList = txOutputPowerList ? txOutputPowerList : [];
        }
    });

    const handleUpdateName = () => {
        update(projectRef, {
            project_name: newProjectName,
        });
    };
</script>

<main>
    <div id="add-project-modal">
        <div id="inner-box">
            <div on:click={() => (optionsModalOpen = false)} id="exit-modal">
                x
            </div>
            <div id="inner-box-title">"{$currentProjectName}" Options</div>
            <div id="options-wrapper">
                <div id="options-item">
                    <div class="profile-item-title">Name:</div>
                    <form on:submit|preventDefault={handleUpdateName}>
                        <div class="profile-item">
                            <input
                                type="text"
                                placeholder={$currentProjectName != "" &&
                                    "Project Name"}
                                bind:value={newProjectName}
                            />
                        </div>
                        <button type="submit">Save</button>
                    </form>
                </div>
                <div id="options-item">
                    <div>Frequency:</div>
                    {#each frequenciesList as frequency}
                        <div id="info-box-freq">
                            {frequency.freq} MHz
                        </div>
                    {/each}
                </div>
                <div id="options-item">
                    <div>Antenna Output Power:</div>
                    {#each outputPowerList as power}
                        <div id="info-box-freq">
                            {power.power} dBm
                        </div>
                    {/each}
                </div>
            </div>
        </div>
    </div>
</main>

<style>
    #add-project-modal {
        z-index: 1001;
        /* top: 0;
        left: 0; */
        position: absolute;
        width: 85%;
        height: 100%;
        background-color: rgba(0, 0, 0, 50%);
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    #inner-box {
        position: relative;
        min-width: 50rem;
        width: 50vw;
        min-height: 36rem;
        height: 40vh;
        margin-top: 12vh;
        background-color: #252321;
        border-radius: 10px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
    }

    #inner-box-title {
        color: white;
        font-weight: bold;
        font-size: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    #exit-modal {
        position: absolute;
        color: white;
        top: 0.5rem;
        left: 0.5rem;
        cursor: pointer;
        font-size: 1.25rem;
        transition: 0.2s;
    }

    #exit-modal:hover {
        color: #00d1ff;
        transition: 0.2s;
    }

    #options-wrapper {
        min-width: 35rem;
        width: 35vw;
        min-height: 25rem;
        height: 25vh;
    }

    #options-item {
        color: white;
        display: flex;
        margin-bottom: 2rem;
    }

    #options-item form {
        display: flex;
        margin-left: 1rem;
    }

    #info-box-freq {
        background-color: gray;
        color: black;
        border-radius: 9px;
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: 1rem;
        font-weight: bold;
    }
</style>

<script>
    const legend = 'images/LEGEND2.png'
</script>

<main>
    <div class="overlay">
        <div id="tool-settings-title">
            <div style="font-weight: bold;">HEATMAP KEY</div>
        </div>
        <img src={legend} alt="legend" class="legend" style="width: 10rem;"/>
        <!-- <div
            class="color-block"
            style="background-color: rgb(255, 0, 0);"
        >
            <div>-39</div>
            <div>-</div>
            <div>-30</div>
        </div>
        <div class="color-block" style="background-color: rgb(255, 204, 230);">
            <div>-49</div>
            <div>-</div>
            <div>-40</div>
        </div>
        <div class="color-block" style="background-color: rgb(255, 128, 179);">
            <div>-59</div>
            <div>-</div>
            <div>-50</div>
        </div>
        <div class="color-block" style="background-color: rgb(204, 26, 128);">
            <div>-69</div>
            <div>-</div>
            <div>-60</div>
        </div>
        <div class="color-block" style="background-color: rgb(255, 128, 0);">
            <div>-79</div>
            <div>-</div>
            <div>-70</div>
        </div>
        <div
            class="color-block"
            style="background-color: rgb(255, 255, 0); color: black;"
        >
            <div>-89</div>
            <div>-</div>
            <div>-80</div>
        </div>
        <div class="color-block" style="background-color: rgb(0, 255, 0);">
            <div>-99</div>
            <div>-</div>
            <div>-90</div>
        </div>
        <div
            class="color-block"
            style="background-color: rgb(0, 0, 255);"
        >
            <div>-150</div>
            <div>-</div>
            <div>-100</div>
        </div> -->
    </div>
</main>

<style>
    .overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        /* bottom: 1rem; */
        color: white;
        /* margin-top: 1rem;
        margin-left: 1rem; */
        /* margin-left: 1vw; */
        background-color: #252321;
        margin-left: auto;
        margin-right: auto;
        left: 1.15rem;
        /* right: 0; */
        /* text-align: center; */
        bottom: 1rem;
        width: 14vw;
        border-radius: 9px;
        padding-right: 0.25rem;
        padding-left: 0.25rem;
        height: 45vh;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
    .color-block {
        height: 2rem;
        /* width: 60px; */
        width: 100%;
        display: flex;
        /* justify-content: ; */
        align-items: center;
    }

    .legend {
        width: 10vw;
        height: 34vh;
    }

    #tool-settings-title {
        display: flex;
        justify-content: center;
        /* padding-top: 0.5rem; */
        padding-bottom: 0.5rem;
        position: absolute;
        top: 0.5rem;
    }
</style>

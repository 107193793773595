<script>
    import {
        createUserWithEmailAndPassword,
        getAuth,
        updateProfile,
    } from "firebase/auth";
    import { set, ref, getDatabase } from "firebase/database";
    import { isLoggedIn } from "../../stores";

    const auth = getAuth();
    const db = getDatabase();

    let signUpError = false;
    let errorMessage = "";
    let name = "";
    let company = "";
    let email = "";
    let pwd = "";
    let confirm_pwd = "";
    let userId = "";

    const resetErrors = () => {
        setTimeout(() => {
            signUpError = false;
            errorMessage = "";
        }, 4000);
    };

    const submitForm = () => {
        if (pwd !== confirm_pwd) {
            signUpError = true;
            errorMessage = "passwords do not match!";
            resetErrors();
        } else if (!name.length || !company.length) {
            signUpError = true;
            errorMessage = "please fill out all forms";
            resetErrors();
        } else {
            createUserWithEmailAndPassword(auth, email, pwd)
                .then(async (userCredential) => {
                    await set(
                        ref(db, `users/${userCredential.user.uid}/metadata/`),
                        {
                            name: name,
                            company: company,
                            email: email,
                            user_id: userCredential.user.uid,
                        }
                    ).then(() => isLoggedIn.set(true));
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const erMessage = error.message;
                    signUpError = true;
                    //TODO: change error message from firebase default
                    errorMessage = erMessage;
                    resetErrors();
                });
        }
    };
</script>

<main>
    <div id="login-wrapper">
        <div id="login-title">Sign Up</div>
        <form class="content" on:submit|preventDefault={submitForm}>
            <input
                placeholder="name"
                id="name"
                class="form"
                bind:value={name}
            />
            <input
                placeholder="company"
                id="company"
                class="form"
                bind:value={company}
            />
            <input
                type="email"
                placeholder="email"
                id="usr"
                class="form"
                bind:value={email}
            />
            <input
                type="password"
                placeholder="password"
                id="pwd"
                class="form"
                bind:value={pwd}
            />
            <input
                type="password"
                placeholder="confirm password"
                id="confirm-pwd"
                bind:value={confirm_pwd}
            />
            <div style="display: flex;">
                <button id="submit-button" type="submit"> ></button>
                {#if signUpError}
                    <div id="pwd-error">{errorMessage}</div>
                {/if}
            </div>
        </form>
    </div>
</main>

<style>
    main {
        color: white;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    input {
        border: none;
        background-image: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: 2.75rem;
        width: 20rem;
        border-radius: 10px;
        background-color: white;
        font-weight: bold;
        padding-left: 0.5rem;
    }

    ::placeholder {
        font-weight: bold;
        color: #c3c3c3;
    }

    ::-moz-placeholder {
        font-weight: bold;
        color: #c3c3c3;
    }
    /* 
    #name {
        margin-bottom: 1rem;
    }

    #company {
        margin-bottom: 1rem;
    } */

    /* #pwd {
        margin-bottom: 1rem;
    } */
    #confirm-pwd {
        margin-bottom: 0.5rem;
    }

    /* #usr {
        margin-bottom: 1rem;
    } */

    .form {
        margin-bottom: 1rem;
    }

    #login-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    .content {
        display: flex;
        flex-direction: column;
    }

    #submit-button {
        background-color: #00d1ff;
        color: black;
        border-radius: 10px;
        height: 1.5rem;
        width: 3rem;
        font-weight: bold;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        transition: 0.2s;
    }

    #submit-button:hover {
        background-color: aqua;
        transition: 0.2s;
        cursor: pointer;
    }

    #submit-button:active {
        background-color: whitesmoke;
    }

    #pwd-error {
        color: red;
        font-style: italic;
        font-size: 0.75rem;
        padding-top: 0.25rem;
        padding-left: 0.5rem;
    }
</style>

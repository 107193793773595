<script>
    export let connectedToServer;
</script>

<main>
    <div id="status-wrapper">
        <div>SOCKET CONNECTION:</div>
        <div
            class="socket-status"
            style={connectedToServer
                ? "background-color: #39ff14;"
                : "background-color: #FF143B;"}
        />
    </div>
</main>

<style>
    #status-wrapper {
        bottom: 1rem;
        margin-right: 1vw;
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: center;
        right: 15vw;
        /* margin-top: 1rem; */
        height: 40px;
        min-width: 8rem;
        width: 14vw;
        /* margin-left: 78.5rem; */
        background-color: #252321;
        color: white;
        border-radius: 9px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
        font-weight: bold;
        font-size: 0.75rem;
    }
    .socket-status {
        margin-left: 0.5rem;
        height: 1rem;
        width: 1rem;
        border-radius: 100%;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
</style>

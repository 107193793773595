<script>
    export let addProjectModalOpen;
    import AddProjectFiles from "./AddProjectFiles.svelte";
</script>

<main>
    <div id="add-project-modal">
        <div id="inner-box">
            <div on:click={() => (addProjectModalOpen = false)} id="exit-modal">
                x
            </div>
            <div id="inner-box-title">Create New Project</div>
            <svelte:component this={AddProjectFiles} />
        </div>
    </div>
</main>

<style>
    #add-project-modal {
        z-index: 1001;
        /* top: 0;
        left: 0; */
        position: absolute;
        width: 85%;
        height: 95%;
        background-color: rgba(0, 0, 0, 50%);
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    #inner-box {
        position: relative;
        min-width: 50rem;
        width: 50vw;
        min-height: 36rem;
        height: 40vh;
        margin-top: 12vh;
        background-color: #252321;
        border-radius: 10px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
    }

    #inner-box-title {
        color: white;
        font-weight: bold;
        font-size: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    #exit-modal {
        position: absolute;
        color: white;
        top: 0.5rem;
        left: 0.5rem;
        cursor: pointer;
        font-size: 1.25rem;
        transition: 0.2s;
    }

    #exit-modal:hover {
        color: #00d1ff;
        transition: 0.2s;
    }
</style>

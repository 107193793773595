import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCB342cUMX2-TbJbekRQTXFW1tTNYxnGAY",
    authDomain: "designrf-propagation.firebaseapp.com",
    projectId: "designrf-propagation",
    databaseURL: "https://designrf-propagation-default-rtdb.firebaseio.com/",
    storageBucket: "designrf-propagation.appspot.com",
    messagingSenderId: "721921007819",
    appId: "1:721921007819:web:f264ba1396b3617f0dfb4e",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

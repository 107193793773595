import { getDatabase, ref, update, onValue } from "firebase/database";
import {
    getStorage,
    ref as storgRef,
    uploadBytes,
    listAll,
    getDownloadURL,
} from "firebase/storage";

import {
    currentProjectName,
    userId,
    savingData,
    currentProjectId,
    currentPageIndex,
    currentProjectData,
} from "../stores"

export const formatURLs = async (floorImages, heatmapImages, urlsArray, storage) => {
    floorImages = floorImages.items;
    for (let page of floorImages) {
        let url = await getDownloadURL(
            storgRef(storage, page._location.path_)
        );
        let idx = page.name.slice(0, page.name.indexOf("-"));
        urlsArray.push({
            url: url,
            idx: idx,
        });
    }

    heatmapImages = heatmapImages.items;
    for (let heatmap of heatmapImages) {
        let hmUrl = await getDownloadURL(
            storgRef(storage, heatmap._location.path_)
        );
        let idx = heatmap.name.slice(
            heatmap.name.indexOf("-") + 1,
            heatmap.name.indexOf(".")
        );
        urlsArray[idx].hmUrl = hmUrl;
    }

    return urlsArray;
};

export const formatExportImage = (img) => {
    let smallImage = false;

    let imageHeight = img.height;
    let imageWidth = img.width;

    let largerSide = imageWidth > imageHeight ? imageWidth : imageHeight;

    if (largerSide < 1500) {
        smallImage = true;
        img.height =
            imageHeight > imageWidth
                ? 1500
                : 1500 * (imageHeight / imageWidth);
        img.width =
            imageWidth > imageHeight
                ? 1500
                : 1500 * (imageWidth / imageHeight);
    }

    return { img: img, isSmall: smallImage };
};

export const formatFloorImage = (src, maxSidePixelLength) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = src;
    const imgHeight = img.height;
    const imgWidth = img.width;
    img.height =
        imgHeight > imgWidth
            ? maxSidePixelLength
            : maxSidePixelLength * (imgHeight / imgWidth);
    img.width =
        imgWidth > imgHeight
            ? maxSidePixelLength
            : maxSidePixelLength * (imgWidth / imgHeight);
    return img;
};